*, *::before, *::after { box-sizing: border-box; }

html { font-family: sans-serif; line-height: 1.15; -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; -ms-overflow-style: scrollbar; -webkit-tap-highlight-color: transparent; }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section { display: block; }

[tabindex="-1"]:focus { outline: 0; }

hr { box-sizing: content-box; height: 0; overflow: visible; }

abbr[title], abbr[data-original-title] { text-decoration: underline; cursor: help; border-bottom: 0; }

address { margin-bottom: 1rem; font-style: normal; line-height: inherit; }

ol, ul, dl { margin-top: 0; margin-bottom: 1rem; }

ol ol, ul ul, ol ul, ul ol { margin-bottom: 0; }

dd { margin-bottom: .5rem; margin-left: 0; }

blockquote { margin: 0 0 1rem; }

dfn { font-style: italic; }

b, strong { font-weight: bolder; }

small { font-size: 80%; }

sub, sup { position: relative; font-size: 75%; line-height: 0; vertical-align: baseline; }

sub { bottom: -.25em; }

sup { top: -.5em; }

a { -webkit-text-decoration-skip: objects; }

a:not([href]):not([tabindex]) { color: inherit; text-decoration: none; }

a:not([href]):not([tabindex]):focus { outline: 0; }

pre, code, kbd, samp { font-size: 1em; }

pre { margin-top: 0; margin-bottom: 1rem; overflow: auto; -ms-overflow-style: scrollbar; }

figure { margin: 0; }

img { vertical-align: middle; border-style: none; }

svg { overflow: hidden; vertical-align: middle; }

table { border-collapse: collapse; }

caption { text-align: left; caption-side: bottom; }

th { text-align: inherit; }

label { display: inline-block; }

button { border-radius: 0; }

input, button, select, optgroup, textarea { border: none; margin: 0; font-family: inherit; font-size: inherit; line-height: inherit; }

button, input { overflow: visible; }

button, select { text-transform: none; }

button, html [type="button"], [type="reset"], [type="submit"] { -webkit-appearance: button; }

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner { padding: 0; border-style: none; }

input[type="radio"], input[type="checkbox"] { box-sizing: border-box; padding: 0; }

input[type="date"], input[type="time"], input[type="datetime-local"], input[type="month"] { -webkit-appearance: listbox; }

textarea { overflow: auto; resize: vertical; }

fieldset { min-width: 0; padding: 0; margin: 0; border: 0; }

legend { display: block; width: 100%; max-width: 100%; padding: 0; margin-bottom: .5rem; font-size: 1.5rem; line-height: inherit; color: inherit; white-space: normal; }

progress { vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button { height: auto; }

[type="search"] { outline-offset: -2px; -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration { -webkit-appearance: none; }

::-webkit-file-upload-button { font: inherit; -webkit-appearance: button; }

output { display: inline-block; }

summary { display: list-item; cursor: pointer; }

template { display: none; }

[hidden] { display: none !important; }

/*---------------pseudo element default styles---------------*/
/*---------------------pseudo full size---------------------*/
/*--------------------proportional ratio--------------------*/
/*--------------------placeholder styles--------------------*/
/*-------------------------clearfix-------------------------*/
/*------------------------transition------------------------*/
/*--------------------background image--------------------*/
/*--------------------absolute alignment--------------------*/
/*------------------------animation------------------------*/
/*------------------make triangle------------------*/
/*---------------------------fonts---------------------------*/
/*-----------------------for browsers-----------------------*/
/*-----------------------map get recursive-----------------------*/
/*-----------------------font size-----------------------*/
/* Colors variables start */
/* Colors variables end */
/* Fonts variables start */
/* Fonts variables end */
/* Reboot variables start */
/* Grid variables start */
/* Grid variables end */
/* Responsive font-size start */
/* Responsive font-size end */
/*Common variables start */
/*Common variables end */
/*---------------pseudo element default styles---------------*/
/*---------------------pseudo full size---------------------*/
/*--------------------proportional ratio--------------------*/
/*--------------------placeholder styles--------------------*/
/*-------------------------clearfix-------------------------*/
/*------------------------transition------------------------*/
/*--------------------background image--------------------*/
/*--------------------absolute alignment--------------------*/
/*------------------------animation------------------------*/
/*------------------make triangle------------------*/
/*---------------------------fonts---------------------------*/
/*-----------------------for browsers-----------------------*/
/*-----------------------map get recursive-----------------------*/
/*-----------------------font size-----------------------*/
* { outline: none; }

html, body { height: 100%; }

body { margin: 0; padding: 0; width: 100%; height: auto; font-family: "OpenSansRegular", sans-serif; color: #101010; line-height: 1.3; font-size: 16px; cursor: default; position: relative; -webkit-overflow-scrolling: touch; }

body.modal-is-open, body.menu-is-open { overflow: hidden; }

@media all and (min-width: 1200px) { body.modal-is-open, body.menu-is-open { padding-right: 1em; } }

body.is-blocked { pointer-events: none; }

h1, h2, h3, h4, h5, h6 { font-weight: normal; font-family: "RubikRegular", sans-serif; }

ul, ol, li { margin: 0; padding: 0; list-style: none; }

button { border: none; padding: 0; margin: 0; background-color: transparent; cursor: pointer; }

button[type="button"]:focus, button[type="submit"]:focus { outline: none; }

a { color: inherit; text-decoration: none; }

@media (hover: hover) { a:hover { color: inherit; } }

a:hover, a:active { color: inherit; }

input[type='number'] { -moz-appearance: textfield; }

input[type='number']::-webkit-outer-spin-button, input[type='number']::-webkit-inner-spin-button { -webkit-appearance: none; margin: 0; }

input:focus, textarea:focus { outline: none; }

svg { max-width: 100%; max-height: 100%; }

img { max-width: 100%; }

/* for extends */
.page__title, .auth__title, .modal__title, .home__search-results-title { font-family: "RubikRegular", sans-serif; line-height: 1.1; }

.page__title, .auth__title, .modal__title, .home__search-results-title { font-size: 1.375rem; }

@media all and (min-width: 992px) { .page__title, .auth__title, .modal__title, .home__search-results-title { font-size: 1.75rem; } }

.rating-tooltip__button, .home__search-button, .review-card__btn:nth-child(odd), .reviews-create__form-button:not([type="submit"]), .header__login-btn, .form__button, .button, .modal--success .modal__button, .cards__create-button, .home__search-tabs-nav-item, .home__search-tabs-button, .home__search-form-button, .review-card__btn:nth-child(even), .reviews-create__form-button[type="submit"] { display: inline-block; border: none; user-select: none; box-shadow: none; border-radius: 6px; text-align: center; line-height: 1; padding: 13px 30px; transition: all 0.3s ease; font-size: 0.875rem; }

@media (hover: hover) { .rating-tooltip__button:hover, .home__search-button:hover, .review-card__btn:hover:nth-child(odd), .reviews-create__form-button:hover:not([type="submit"]), .header__login-btn:hover, .form__button:hover, .button:hover, .modal--success .modal__button:hover, .cards__create-button:hover, .home__search-tabs-nav-item:hover, .home__search-tabs-button:hover, .home__search-form-button:hover, .review-card__btn:hover:nth-child(even), .reviews-create__form-button:hover[type="submit"] { cursor: pointer; } }

@media all and (min-width: 992px) { .rating-tooltip__button, .home__search-button, .review-card__btn:nth-child(odd), .reviews-create__form-button:not([type="submit"]), .header__login-btn, .form__button, .button, .modal--success .modal__button, .cards__create-button, .home__search-tabs-nav-item, .home__search-tabs-button, .home__search-form-button, .review-card__btn:nth-child(even), .reviews-create__form-button[type="submit"] { font-size: 1rem; padding: 13px 50px; } }

.rating-tooltip__button, .home__search-button, .review-card__btn:nth-child(odd), .reviews-create__form-button:not([type="submit"]) { border: 1px solid #CFCFCF; }

.header__login-btn, .form__button, .button, .modal--success .modal__button, .cards__create-button, .home__search-tabs-nav-item, .home__search-tabs-button, .home__search-form-button, .review-card__btn:nth-child(even), .reviews-create__form-button[type="submit"] { background-color: #575757; color: #fff; }

@media (hover: hover) { .header__login-btn:hover, .form__button:hover, .button:hover, .modal--success .modal__button:hover, .cards__create-button:hover, .home__search-tabs-nav-item:hover, .home__search-tabs-button:hover, .home__search-form-button:hover, .review-card__btn:hover:nth-child(even), .reviews-create__form-button:hover[type="submit"] { color: #fff; background-color: #444444; } }

.form__button, .button, .modal--success .modal__button, .cards__create-button, .review-card__btn:nth-child(even) { padding: 15px 30px; box-shadow: 0 6px 10px rgba(87, 87, 87, 0.15); }

@media all and (min-width: 992px) { .form__button, .button, .modal--success .modal__button, .cards__create-button, .review-card__btn:nth-child(even) { padding: 17px 34px; } }

/* common */
.page { position: relative; width: 100%; height: auto; min-height: 100vh; display: flex; flex-direction: column; overflow: hidden; background-color: #eeeeee; }

.page__header { display: flex; align-items: center; justify-content: space-between; }

.page .content { padding-top: 80px; padding-bottom: 100px; width: 100%; flex: 1 0 auto; }

@media all and (min-width: 992px) { .page .content { padding-top: 40px; } }

.page .header, .page .footer { flex: 0 0 auto; }

.page--profile, .page--auth { background-color: #E7EAF0; }

.page--full .content { display: flex; }

.page--full .content .container { flex: 1 0 auto; display: flex; align-items: center; justify-content: center; flex-direction: column; }

.header { position: fixed; top: 0; left: 0; z-index: 130; width: 100%; height: 50px; background-color: #fff; }

.menu-is-open .header .header__top { box-shadow: none; border-bottom-color: #F1F1F1; }

.menu-is-open .header .header__top .header__login { opacity: 1; visibility: visible; }

@media all and (min-width: 992px) { .header { position: static; height: 60px; } }

@media all and (min-width: 1400px) { .header { height: auto; } }

.header__top { height: 100%; padding-top: 0; padding-bottom: 0; display: flex; align-items: center; justify-content: space-between; box-shadow: 0 0 20px rgba(0, 0, 0, 0.1); border-bottom: 1px solid transparent; transition: all 0.3s ease; }

@media all and (min-width: 992px) { .header__top { padding-top: 10px; padding-bottom: 15px; } }

@media all and (min-width: 1400px) { .header__top { height: auto; } }

.header__top-left { position: relative; display: flex; align-items: center; justify-content: flex-start; }

.header__top-left .header__login { position: absolute; top: 0; left: 0; width: 100%; height: 100%; transition: all 0.3s ease; opacity: 0; visibility: hidden; z-index: 2; background-color: #fff; margin: 0; }

.header__top-right { display: flex; align-items: center; justify-content: flex-end; }

.header__bottom { width: 100%; background-color: #F9F9F9; }

.header__logo { flex-shrink: 0; display: flex; align-items: center; justify-content: center; }

.header__logo img { width: auto; height: 30px; }

@media all and (min-width: 992px) { .header__logo { margin-right: 50px; }
  .header__logo img { width: 120px; height: auto; } }

.header__menu { flex-grow: 1; }

.header__phone { transition: all 0.3s ease; }

@media (hover: hover) { .header__phone:hover { color: #4B5DFF; } }

.header__phone:active { color: #4B5DFF; }

.header__login { position: relative; margin-left: 70px; display: flex; align-items: center; }

.header__login:hover .header__login-exit { opacity: 1; visibility: visible; }

.header__login-icon { flex-shrink: 0; display: flex; align-items: center; justify-content: center; margin-right: 10px; }

.header__login-icon svg { fill: #4B5DFF; width: 17px; height: 17px; }

.header__login-separator { margin: 0 4px; }

.header__login-user { position: relative; z-index: 5; padding: 10px; display: flex; align-items: center; }

.header__login-user-icon { display: flex; align-items: center; justify-content: center; margin-right: 10px; }

.header__login-user-icon svg { fill: rgba(16, 16, 16, 0.7); width: 17px; height: 17px; transition: all 0.3s ease; }

.header__login-user-text { font-family: "OpenSansSemiBold", sans-serif; color: rgba(16, 16, 16, 0.7); transition: all 0.3s ease; }

@media (hover: hover) { .header__login-user:hover .header__login-user-icon svg { fill: #4B5DFF; }
  .header__login-user:hover .header__login-user-text { color: #4B5DFF; } }

.header__login-user:active .header__login-user-icon svg { fill: #4B5DFF; }

.header__login-user:active .header__login-user-text { color: #4B5DFF; }

.header__login-link { transition: all 0.3s ease; }

@media (hover: hover) { .header__login-link:hover { color: #4B5DFF; } }

.header__login-link:active { color: #4B5DFF; }

.header__login-exit { position: absolute; z-index: 2; width: 100%; height: 100px; top: 0; left: 0; box-shadow: 0 0 20px rgba(0, 0, 0, 0.1); border-radius: 6px; padding: 15px 10px; background-color: #fff; display: flex; flex-direction: column; justify-content: flex-end; align-items: flex-start; transition: all 0.3s ease; opacity: 0; visibility: hidden; }

.header__login-btn { padding: 6px 23px; }

.footer { width: 100%; padding-top: 1em; padding-bottom: 80px; background-color: #fff; }

@media all and (min-width: 992px) { .footer { padding-top: 30px; padding-bottom: 40px; } }

.footer__logo { flex-shrink: 0; display: flex; align-items: center; justify-content: center; justify-content: flex-start; }

.footer__logo img { width: auto; height: 30px; }

@media all and (min-width: 992px) { .footer__logo { margin-right: 50px; }
  .footer__logo img { width: 120px; height: auto; } }

.footer__list { width: 100%; }

@media all and (min-width: 992px) { .footer__list { column-count: 2; } }

@media all and (min-width: 1600px) { .footer__list { column-count: 3; } }

.footer__list-item { display: flex; width: 100%; height: 100%; font-size: 0.875rem; line-height: 2; transition: all 0.3s ease; }

@media (hover: hover) { .footer__list-item:hover { color: #4B5DFF; } }

.footer__list-item:active { color: #4B5DFF; }

.footer__contacts { display: flex; flex-direction: column; }

.footer__contacts-item { font-size: 0.875rem; line-height: 2; transition: all 0.3s ease; }

@media (hover: hover) { .footer__contacts-item:hover { color: #4B5DFF; } }

.footer__contacts-item:active { color: #4B5DFF; }

.footer__link-group { display: flex; }

@media all and (min-width: 992px) { .footer__link-group { justify-content: flex-end; } }

.footer__link { margin-right: 20px; }

.footer__link:last-child { margin-right: 0; }

.breadcrumbs { display: flex; align-items: center; justify-content: center; flex-wrap: wrap; justify-content: flex-start; margin-top: 5px; }

.breadcrumbs__item { display: flex; align-items: center; justify-content: center; font-size: 0.75rem; color: rgba(16, 16, 16, 0.5); line-height: 1.2; margin-bottom: 5px; }

.breadcrumbs__item-separator { flex-shrink: 0; display: flex; align-items: center; justify-content: center; margin: 0 4px; }

.breadcrumbs__item-link { transition: .3s; text-decoration: none; }

@media (hover: hover) { .breadcrumbs__item-link:hover { cursor: pointer; color: #101010; } }

.breadcrumbs__item-link:active { color: #101010; }

.breadcrumbs__item-current { color: rgba(90, 90, 90, 0.5); }

.menu { width: 100%; display: flex; }

.menu__item { flex-grow: 1; }

.menu__item:first-child, .menu__item:last-child { flex-grow: 0.5; }

.menu__item:first-child .menu__link { justify-content: flex-start; }

.menu__item:last-child .menu__link { justify-content: flex-end; }

.menu__item.active .menu__link { color: #4B5DFF; }

.menu__link { display: flex; align-items: center; justify-content: center; width: 100%; height: 100%; transition: all 0.3s ease; font-size: 0.875rem; color: rgba(16, 16, 16, 0.7); font-family: "OpenSansSemiBold", sans-serif; }

.menu__link-count { position: relative; top: -50%; color: #4B5DFF; font-size: 0.6875rem; font-family: "OpenSansBold", sans-serif; }

@media (hover: hover) { .menu__link:hover:not(.active) { cursor: pointer; color: #4B5DFF; } }

.menu__link:active { color: #4B5DFF; }

@media all and (min-width: 1400px) { .menu__link { font-size: 1rem; } }

.menu--main .menu__link { padding: 0 20px; }

.menu--main .menu__item:first-child .menu__link { padding-left: 0; }

.menu--main .menu__item:last-child .menu__link { padding-right: 0; }

.menu--detail .menu__item { flex-grow: 0; padding: 0 30px; }

.menu--detail .menu__item:first-child { padding-left: 0; }

.menu--detail .menu__item.active .menu__link { color: #101010; }

.menu--detail .menu__link { font-size: 0.875rem; padding: 12px 0; }

@media (hover: hover) { .menu--detail .menu__link:hover:not(.active) { cursor: pointer; color: #101010; } }

.menu--detail .menu__link:active { color: #101010; }

.menuButton { display: flex; align-items: center; justify-content: center; width: 20px; height: 30px; }

@media all and (min-width: 992px) { .menuButton { width: 30px; } }

@media (hover: hover) { .menuButton:hover { cursor: pointer; }
  .menuButton:hover .menuButton__burger:before { top: -8px; }
  .menuButton:hover .menuButton__burger:after { top: 8px; } }

.menu-is-open .menuButton .menuButton__burger { background-color: transparent; }

.menu-is-open .menuButton .menuButton__burger:before, .menu-is-open .menuButton .menuButton__burger:after { top: 0; }

.menu-is-open .menuButton .menuButton__burger:before { transform: rotate(45deg); }

.menu-is-open .menuButton .menuButton__burger:after { transform: rotate(-45deg); }

.menuButton__burger { position: relative; height: 2px; width: 100%; background-color: #4B5DFF; transition: .2s; }

.menuButton__burger:before, .menuButton__burger:after { content: ''; position: absolute; left: 0; width: 100%; height: 2px; background-color: #4B5DFF; transition: .3s; }

.menuButton__burger:before { top: -6px; }

.menuButton__burger:after { top: 6px; }

@media all and (min-width: 992px) { .menuButton__burger { width: 30px; }
  .menuButton__burger:before { top: -10px; }
  .menuButton__burger:after { top: 10px; } }

.menuMobile { position: absolute; top: 0; left: 0; width: 100%; height: 100%; position: fixed; z-index: 120; background-color: #fff; padding: 50px 0; transition: opacity .4s, visibility .4s, transform .4s; opacity: 0; visibility: hidden; display: flex; flex-direction: column; transform: translateY(-10%); overflow: auto; }

.menu-is-open .menuMobile { opacity: 1; visibility: visible; transform: translateY(0); }

@media all and (min-width: 992px) { .menuMobile { padding-top: 60px; } }

.menuMobile__nav { width: 100%; margin-bottom: 50px; }

.menuMobile__nav-item { border-bottom: 1px solid #F1F1F1; }

.menuMobile__nav-item.active .menuMobile__nav-link { color: #4B5DFF; }

.menuMobile__nav-item.open .menuMobile__nav-title .text { color: #4B5DFF; }

.menuMobile__nav-item.open .menuMobile__nav-title .icon { transform: rotate(180deg); }

.menuMobile__nav-link, .menuMobile__nav-title { display: flex; align-items: center; justify-content: space-between; padding: 1em 0; }

.menuMobile__nav-title .text { transition: all 0.3s ease; }

.menuMobile__nav-title .icon { display: flex; align-items: center; justify-content: center; transition: all 0.3s ease; margin-left: 10px; }

.menuMobile__nav-title .icon svg { fill: #4B5DFF; width: 10px; height: 10px; }

.menuMobile__nav-sub { height: 0; overflow: hidden; position: relative; }

.menuMobile__nav-sub:before { content: ''; position: absolute; width: 100%; height: 1px; left: 0; top: 0; background-color: #F1F1F1; }

.menuMobile__nav-sub-item { background-color: rgba(241, 241, 241, 0.3); border-bottom: 1px solid #F1F1F1; }

.menuMobile__nav-sub-item.active .menuMobile__nav-sub-link { color: #4B5DFF; }

.menuMobile__nav-sub-item:last-child { border-bottom: none; }

.menuMobile__nav-sub-link { display: block; padding: 1em 0; transition: all 0.3s ease; }

.menuMobile__nav-sub-link:active { background-color: #E7EAF0; }

.menuMobile__footer { margin-top: auto; }

.menuMobile__contacts-item { font-size: 0.875rem; display: block; margin-bottom: 0.6em; color: rgba(16, 16, 16, 0.5); }

.menuMobile__link-group { display: flex; margin-top: 20px; }

.menuMobile__link { margin-right: 20px; }

.menuMobile__link:last-child { margin-right: 0; }

.tabBar { position: fixed; z-index: 100; width: 100%; bottom: 0; left: 0; height: 60px; transition: transform .3s; }

.menu-is-open .tabBar { transform: translateY(100%); }

.tabBar.open .tabBar__menu { border-radius: 0; box-shadow: none; }

.tabBar__menu { position: relative; width: 100%; height: 100%; z-index: 2; background-color: #fff; border-radius: 6px 6px 0 0; box-shadow: 0 2px 20px rgba(0, 0, 0, 0.25); padding: 0 20px; display: flex; }

.tabBar__menu-item { flex-grow: 1; width: 25%; }

.tabBar__menu-item.open .tabBar__menu-btn .icon:before { opacity: 1; }

.tabBar__menu-item.open .tabBar__menu-sub { opacity: 1; visibility: visible; }

.tabBar__menu-link, .tabBar__menu-btn { width: 100%; height: 100%; display: flex; align-items: center; justify-content: center; flex-direction: column; }

.tabBar__menu-link .text, .tabBar__menu-btn .text { color: #101010; font-family: "OpenSansSemiBold", sans-serif; font-size: 0.625rem; line-height: 1; white-space: nowrap; }

.tabBar__menu-link .icon, .tabBar__menu-btn .icon { position: relative; width: 30px; height: 30px; border-radius: 50%; display: flex; align-items: center; justify-content: center; margin-bottom: 2px; }

.tabBar__menu-link .icon:before, .tabBar__menu-btn .icon:before { content: ''; position: absolute; z-index: 2; top: 50%; left: 50%; width: 31px; height: 31px; border-radius: 50%; background-color: #E7EAF0; pointer-events: none; opacity: 0; transform: translate3d(-50%, -50%, 0); transition: all 0.3s ease; }

.tabBar__menu-link .icon--double, .tabBar__menu-btn .icon--double { width: auto; }

.tabBar__menu-link .icon--double svg, .tabBar__menu-btn .icon--double svg { margin-right: 5px; }

.tabBar__menu-link .icon--double svg:last-child, .tabBar__menu-btn .icon--double svg:last-child { margin-right: 0; }

.tabBar__menu-link .icon svg, .tabBar__menu-btn .icon svg { position: relative; z-index: 3; fill: #4B5DFF; width: 20px; height: 20px; }

.tabBar__menu-link .icon svg.icon-zoom, .tabBar__menu-link .icon svg.icon-add, .tabBar__menu-btn .icon svg.icon-zoom, .tabBar__menu-btn .icon svg.icon-add { fill: none; }

.tabBar__menu-link .icon svg.icon-zoom, .tabBar__menu-btn .icon svg.icon-zoom { height: 18px; }

.tabBar__menu-link .icon svg.icon-user, .tabBar__menu-btn .icon svg.icon-user { height: 19px; }

.tabBar__menu-link .icon svg.icon-truck, .tabBar__menu-btn .icon svg.icon-truck { width: 26px; height: 26px; }

.tabBar__menu-link:active .icon { background-color: #E7EAF0; }

.tabBar__menu-sub { position: absolute; z-index: 1; width: 100%; height: 50px; bottom: 100%; left: 0; border-radius: 6px 6px 0 0; box-shadow: 0 -10px 15px rgba(0, 0, 0, 0.05), inset 0 0 0 1px #4B5DFF; display: flex; opacity: 0; visibility: hidden; overflow: hidden; transition: all 0.3s ease; background-color: #fff; }

.tabBar__menu-sub-item { width: 50%; display: flex; align-items: center; justify-content: center; text-align: center; border-right: 1px solid #4B5DFF; font-size: 0.75rem; font-family: "OpenSansSemiBold", sans-serif; line-height: 1; transition: all 0.3s ease; }

.tabBar__menu-sub-item:last-child { border-right: none; }

.tabBar__menu-sub-item:active { color: #4B5DFF; }

.photo-loader { position: relative; display: flex; align-items: center; overflow: hidden; }

.photo-loader--circle { top: -10px; width: 60px; height: 60px; background-color: #fff; border-radius: 50%; }

.photo-loader--circle .photo-loader__title { font-size: 0.625rem; text-align: center; text-decoration: none; line-height: 1.3; }

.photo-loader--circle .photo-loader__img { padding: 8px; }

.photo-loader--circle .photo-loader__img img { max-width: 100%; max-height: 100%; }

@media all and (min-width: 768px) { .photo-loader--circle { width: 80px; height: 80px; }
  .photo-loader--circle .photo-loader__img { padding: 10px; } }

.photo-loader__input { position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: 2; opacity: 0; }

@media (hover: hover) { .photo-loader__input:hover { cursor: pointer; }
  .photo-loader__input:hover + .photo-loader__title { color: #4B5DFF; text-decoration-color: transparent; } }

.photo-loader__title { font-size: 0.8125rem; transition: all 0.3s ease; text-decoration: underline; line-height: 1.5; color: #98989C; }

@media all and (min-width: 992px) { .photo-loader__title { font-size: 0.875rem; } }

.photo-loader__icon { display: flex; align-items: center; justify-content: center; width: 40px; height: 40px; border-radius: 50%; margin-right: 15px; border: 1px solid #98989C; display: none; }

.photo-loader__icon svg { width: 16px; height: 16px; fill: rgba(16, 16, 16, 0.4); }

@media all and (min-width: 992px) { .photo-loader__icon { width: 60px; height: 60px; }
  .photo-loader__icon svg { width: 18px; height: 18px; } }

@media all and (min-width: 1200px) { .photo-loader__icon { display: flex; } }

.photo-loader__img { width: 100%; height: 100%; display: flex; align-items: center; justify-content: center; }

.photo-loader__img img { max-width: 100%; max-height: 70px; }

.form { width: 100%; user-select: none; }

.form input[type=text], .form input[type=submit], .form input[type=number]::-webkit-inner-spin-button, .form input[type=number]::-webkit-outer-spin-buttontextarea, .form select { -webkit-appearance: none; -moz-appearance: none; -ms-appearance: none; appearance: none; margin: 0; }

.form input[type=number], .form input[type=text], .form input[type=submit], .form button, .form select { -moz-appearance: textfield; }

.form input:-webkit-autofill, .form input:-webkit-autofill:hover, .form input:-webkit-autofill:focus, .form input:-webkit-autofill, .form textarea:-webkit-autofill, .form textarea:-webkit-autofill:hover, .form textarea:-webkit-autofill:focus, .form select:-webkit-autofill, .form select:-webkit-autofill:hover, .form select:-webkit-autofill:focus { transition: 9999999s ease-in-out 0s; }

.form input:invalid { box-shadow: none; }

.form__item { width: 100%; margin-bottom: 10px; }

.form__item:last-child { margin-bottom: 0; }

.form__field { position: relative; width: 100%; display: flex; align-items: center; }

.form__field-item { position: relative; flex-grow: 1; }

.form__field--multiple .form__field-item { max-width: 50%; }

.form__field--multiple .form__field-item:first-child .form__label { border-radius: 6px 0 0 6px; border-left: 1px solid #CFCFCF; }

.form__field--multiple .form__field-item:last-child .form__label { border-radius: 0 6px 6px 0; border-right: 1px solid #CFCFCF; }

.form__field--multiple .form__field-item:last-child .form__label:after { display: none; }

.form__field--multiple .form__label { position: relative; border-right: none; border-left: none; border-radius: 0; }

.form__field--multiple .form__label:after { content: ''; position: absolute; height: 80%; top: 10%; right: 0; width: 1px; background-color: #CFCFCF; }

.form__label { width: 100%; display: flex; align-items: center; height: 50px; border-radius: 6px; border: 1px solid #CFCFCF; background-color: #fff; margin: 0; }

.form__label--select { border: none; }

.form__label--radio, .form__label--checkbox, .form__label--code { border: none; padding: 0; height: auto; background: none; border-radius: 0; }

@media (hover: hover) { .form__label--radio:hover, .form__label--checkbox:hover, .form__label--code:hover { cursor: pointer; } }

.form__label--textarea { border: none; background: none; height: auto; border-radius: 0; }

.form__label--code { height: 200px; }

@media all and (max-width: 767px) { .form__label--code { height: 150px; } }

.form__label--code .form__input, .form__label--code .form__textarea { font-size: 4rem; font-family: "RubikRegular", sans-serif; text-align: center; }

.form__label--code .form__input.placeholder, .form__label--code .placeholder.form__textarea { color: #101010; }

.form__label--code .form__input:-moz-placeholder, .form__label--code .form__textarea:-moz-placeholder { color: #101010; }

.form__label--code .form__input::-moz-placeholder, .form__label--code .form__textarea::-moz-placeholder { color: #101010; }

.form__label--code .form__input:-ms-input-placeholder, .form__label--code .form__textarea:-ms-input-placeholder { color: #101010; }

.form__label--code .form__input::-webkit-input-placeholder, .form__label--code .form__textarea::-webkit-input-placeholder { color: #101010; }

.form__label--code .form__input:focus.placeholder, .form__label--code .form__textarea:focus.placeholder { color: #101010; }

.form__label--code .form__input:focus:-moz-placeholder, .form__label--code .form__textarea:focus:-moz-placeholder { color: #101010; }

.form__label--code .form__input:focus::-moz-placeholder, .form__label--code .form__textarea:focus::-moz-placeholder { color: #101010; }

.form__label--code .form__input:focus:-ms-input-placeholder, .form__label--code .form__textarea:focus:-ms-input-placeholder { color: #101010; }

.form__label--code .form__input:focus::-webkit-input-placeholder, .form__label--code .form__textarea:focus::-webkit-input-placeholder { color: #101010; }

.form__label--radio .form__radio:checked + .form__radio-icon { box-shadow: inset 0 0 0 5px #4B5DFF; }

@media (hover: hover) { .form__label--radio:hover { cursor: pointer; } }

.form__input, .form__textarea { width: 100%; height: 100%; border: none; outline: none; -moz-appearance: none; background: none; margin: 0; padding: 0 10px; font-size: 0.875rem; transition: all 0.3s ease; }

.form__input.placeholder, .placeholder.form__textarea { transition: .2s; color: rgba(16, 16, 16, 0.4); }

.form__input:-moz-placeholder, .form__textarea:-moz-placeholder { transition: .2s; color: rgba(16, 16, 16, 0.4); }

.form__input::-moz-placeholder, .form__textarea::-moz-placeholder { transition: .2s; color: rgba(16, 16, 16, 0.4); }

.form__input:-ms-input-placeholder, .form__textarea:-ms-input-placeholder { transition: .2s; color: rgba(16, 16, 16, 0.4); }

.form__input::-webkit-input-placeholder, .form__textarea::-webkit-input-placeholder { transition: .2s; color: rgba(16, 16, 16, 0.4); }

.form__input:focus.placeholder, .form__textarea:focus.placeholder { color: transparent; }

.form__input:focus:-moz-placeholder, .form__textarea:focus:-moz-placeholder { color: transparent; }

.form__input:focus::-moz-placeholder, .form__textarea:focus::-moz-placeholder { color: transparent; }

.form__input:focus:-ms-input-placeholder, .form__textarea:focus:-ms-input-placeholder { color: transparent; }

.form__input:focus::-webkit-input-placeholder, .form__textarea:focus::-webkit-input-placeholder { color: transparent; }

.form__input-icon { flex-shrink: 0; display: flex; align-items: center; justify-content: center; padding-right: 10px; }

.form__input-icon svg { width: 16px; height: 16px; }

@media all and (min-width: 992px) { .form__input, .form__textarea { padding: 0 20px; } }

.form__textarea { padding-top: 10px; height: 100px; resize: none; background-color: #fff; border: 1px solid #CFCFCF; border-radius: 6px; }

.form__title { font-size: 1.125rem; margin-bottom: 10px; font-family: "RubikRegular", sans-serif; }

@media all and (min-width: 992px) { .form__title { font-size: 1.5rem; margin-bottom: 15px; } }

.form__subtitle { margin-bottom: 20px; }

.form__caption-wr { display: flex; }

.form__caption { flex-grow: 1; display: block; margin-bottom: 5px; font-size: 0.875rem; }

@media all and (min-width: 992px) { .form__caption { font-size: 1rem; margin-bottom: 10px; } }

.form__info { color: rgba(16, 16, 16, 0.4); font-size: 0.6875rem; }

.form__info a { text-decoration: underline; transition: all 0.3s ease; }

@media (hover: hover) { .form__info a:hover { cursor: pointer; text-decoration-color: transparent; } }

.form__hint { margin-top: 0.5em; color: rgba(16, 16, 16, 0.4); font-size: 0.875rem; }

.form__link { line-height: 1.5; }

.form__link a { color: #4B5DFF; text-decoration: underline; transition: all 0.3s ease; }

@media (hover: hover) { .form__link a:hover { cursor: pointer; text-decoration-color: transparent; } }

.form__select { width: 100%; height: 100%; color: #101010; }

.form__select .ss-single-selected, .form__select .ss-multi-selected { height: 100%; border: 1px solid #CFCFCF; border-radius: 6px; box-shadow: none; padding: 0 10px; font-size: 0.875rem; }

.form__select .ss-single-selected .ss-arrow span, .form__select .ss-multi-selected .ss-arrow span { border-color: #4B5DFF; }

.form__select .ss-single-selected .ss-add, .form__select .ss-multi-selected .ss-add { position: absolute; height: 100%; top: 0; right: 0; width: 34px; margin: 0; flex: 0; display: flex; align-items: center; justify-content: center; }

.form__select .ss-single-selected .ss-add .ss-plus, .form__select .ss-multi-selected .ss-add .ss-plus { background-color: #4B5DFF; }

.form__select .ss-single-selected .ss-add .ss-plus:after, .form__select .ss-multi-selected .ss-add .ss-plus:after { background-color: #4B5DFF; }

@media all and (min-width: 992px) { .form__select .ss-single-selected, .form__select .ss-multi-selected { padding: 0 10px 0 20px; } }

.form__select .ss-multi-selected { align-items: center; padding-right: 34px; }

.form__select .ss-multi-selected .ss-values { width: 100%; overflow: hidden; flex-wrap: nowrap; }

.form__select .ss-multi-selected .ss-values .ss-disabled { display: inline; margin: 0; padding: 0; color: rgba(16, 16, 16, 0.4); }

.form__select .ss-multi-selected .ss-values .ss-value { padding: 6px 8px; margin-top: 0; margin-bottom: 0; font-size: 0.875rem; color: #101010; background-color: rgba(75, 93, 255, 0.1); white-space: nowrap; }

.form__select .ss-multi-selected .ss-values .ss-value-delete { font-size: 0.875rem; font-family: "OpenSansSemiBold", sans-serif; color: #FF8710; }

.form__select .ss-content { border-top-color: #4B5DFF; }

.form__select .ss-content .ss-list .ss-option { transition: all 0.3s ease; font-size: 0.875rem; padding-top: 14px; padding-bottom: 14px; }

.form__select .ss-content .ss-list .ss-option:hover:not(.ss-disabled) { color: #101010; background-color: rgba(207, 207, 207, 0.3); }

.form__select .ss-content .ss-list .ss-option.ss-disabled { cursor: default; color: #101010; background-color: rgba(75, 93, 255, 0.07); }

.form__checkbox, .form__radio { position: absolute; opacity: 0; visibility: hidden; }

.form__checkbox:checked + .form__checkbox-icon svg { opacity: 1; }

.form__checkbox-icon { display: flex; align-items: center; justify-content: center; width: 20px; height: 20px; background-color: rgba(75, 93, 255, 0.1); border-radius: 3px; }

.form__checkbox-icon svg { fill: #4B5DFF; width: 14px; height: 14px; transition: all 0.3s ease; opacity: 0; }

.form__checkbox-caption { margin-left: 10px; user-select: none; font-size: 0.875rem; transition: all 0.3s ease; }

.form__radio-icon { flex-shrink: 0; width: 20px; height: 20px; border-radius: 50%; margin-right: 10px; box-shadow: inset 0 0 0 2px #4B5DFF; transition: all 0.3s ease; }

.form .timer-start a { transition: all 0.3s ease; pointer-events: none !important; opacity: 0.3 !important; }

.form__switch-button { display: flex; align-items: center; pointer-events: none; }

.form__switch-button.switch-on .form__switch-button-icon:after { transform: translate3d(26px, -50%, 0); }

@media (hover: hover) { .form__switch-button:hover { cursor: pointer; } }

.form__switch-button-option { transition: all 0.3s ease; font-size: 0.75rem; color: #101010; }

.form__switch-button-option.active { color: #4B5DFF; }

@media all and (min-width: 576px) { .form__switch-button-option { font-size: 0.875rem; } }

@media all and (min-width: 992px) { .form__switch-button-option { font-size: 1rem; } }

.form__switch-button-icon { pointer-events: auto; display: block; position: relative; width: 52px; height: 26px; border-radius: 26px; border: 1px solid rgba(202, 202, 211, 0.5); margin: 0 10px; }

.form__switch-button-icon:after { content: ''; position: absolute; flex-shrink: 0; display: block; top: 50%; width: 22px; height: 22px; left: 0; border-radius: 50%; background-color: #4B5DFF; box-shadow: 0 4px 4px rgba(59, 42, 219, 0.2); transition: all 0.3s ease; transform: translate3d(2px, -50%, 0); }

.form__switch-content { width: 100%; margin-top: 20px; }

.form__switch-content-item { width: 100%; display: none; }

.form__switch-content-item.active { display: block; }

.form--search .form__input, .form--search .form__textarea { padding: 0 10px; }

.form--search .form__select .ss-single-selected { padding: 0 10px; }

.form--create .form__caption { font-size: 0.875rem; margin-bottom: 5px; }

.form--create .form__label { height: 50px; }

.form--create .form__label--switch { height: 26px; }

.form--create .form__input, .form--create .form__textarea { font-size: 0.875rem; }

@media all and (min-width: 992px) { .form--create .form__input, .form--create .form__textarea { font-size: 1rem; } }

.form--create .form__select .ss-single-selected { font-size: 0.875rem; }

@media all and (min-width: 992px) { .form--create .form__select .ss-single-selected { font-size: 1rem; } }

.form--create .form__button { padding: 16px 30px; }

.form--create .form__item { margin-bottom: 30px; }

.form--create .form__item:last-child { margin-bottom: 0; }

.form--create .form__item--cargo .form__field { margin-bottom: 20px; }

.form--create .form__item--cargo .form__field .row { width: 100%; }

.form--create .form__item--cargo .form__field:last-child { margin-bottom: 0; }

.form--create .form__item--route .form__label { position: relative; }

.form--create .form__item--date .form__caption { flex-grow: 0; margin-bottom: 0; font-size: 0.875rem; }

@media all and (min-width: 992px) { .form--create .form__item--date .form__caption { font-size: 1rem; } }

.form--create .form__item--date .form__label { margin: 0 15px; }

.form--create .form__item--date .form__label--date { width: auto; max-width: 300px; flex-grow: 1; margin-right: 0; }

@media all and (min-width: 576px) { .form--create .form__item--date .form__label--date { width: 300px; } }

.form--create .form__item--date .form__label--select { width: 90px; }

.form--create .form__item--date .form__field-item { flex-grow: 0; display: flex; align-items: center; justify-content: flex-start; }

.form--create .form__item--payment .form__caption { flex-grow: 0; margin-bottom: 0; margin-left: 10px; font-size: 0.875rem; }

@media all and (min-width: 992px) { .form--create .form__item--payment .form__caption { font-size: 1rem; } }

.form--create .form__item--payment .form__label--price { width: auto; max-width: 300px; flex-grow: 1; margin-right: 0; }

@media all and (min-width: 576px) { .form--create .form__item--payment .form__label--price { width: 300px; } }

.form--create .form__item--payment .form__label--checkbox { width: auto; margin-right: 20px; }

.form--create .form__item--payment .form__label--checkbox:last-child { margin-right: 0; }

.form--create .form__item--payment .form__field { margin-bottom: 20px; }

.form--create .form__item--payment .form__field:last-child { margin-bottom: 0; }

.form--create .form__item--payment .form__field-item { flex-grow: 0; margin-right: 20px; display: flex; flex-wrap: wrap; align-items: center; justify-content: flex-start; }

.form--create .form__item--payment .form__field-item:last-child { margin-right: 0; }

.form--create .form__item--term .form__caption { margin-bottom: 0; margin-left: 10px; font-size: 0.875rem; }

@media all and (min-width: 992px) { .form--create .form__item--term .form__caption { font-size: 1rem; } }

.form--create .form__item--term .form__radio:checked + .form__radio-icon { background-color: rgba(75, 93, 255, 0.1); }

.form--create .form__item--term .form__radio:checked + .form__radio-icon:after { opacity: 1; }

.form--create .form__item--term .form__radio-icon { border-radius: 50%; position: relative; }

.form--create .form__item--term .form__radio-icon:after { content: ''; position: absolute; width: 60%; height: 60%; top: 20%; left: 20%; border-radius: 50%; background-color: #4B5DFF; transition: all 0.3s ease; opacity: 0; }

.form--create .form__item--term .form__label--term { width: 144px; }

.form--create .form__item--term .form__label--radio { margin-right: 20px; }

.form--create .form__item--term .form__label--radio:last-child { margin-right: 0; }

.form--create .form__item--term .form__field { margin-bottom: 20px; }

.form--create .form__item--term .form__field:last-child { margin-bottom: 0; }

.form--create .form__item--term .form__field-item { flex-grow: 0; margin-right: 20px; display: flex; align-items: center; }

.form--create .form__item--term .form__field-item:last-child { margin-right: 0; }

.form--create .form__item--operator .form__caption { margin-bottom: 10px; margin-right: 0; }

@media all and (min-width: 768px) { .form--create .form__item--operator .form__caption { margin-bottom: 0; margin-right: 10px; } }

.form--create .form__item--operator .form__field { justify-content: space-between; }

.form--create .form__item--operator .form__field-item { flex-grow: 0; display: flex; flex-direction: column; }

@media all and (min-width: 768px) { .form--create .form__item--operator .form__field-item { flex-direction: row; align-items: center; } }

.form--create .form__item--operator .form__select { width: 100%; }

.form--create .form__item--operator .form__select .ss-single-selected { font-size: 0.875rem; padding: 0 10px; }

@media all and (min-width: 768px) { .form--create .form__item--operator .form__select { width: 206px; } }

.form--profile .form__tab { display: none; }

.form--profile .form__tab.active { display: block; }

.form--profile .form__slide-content { height: 0; visibility: hidden; }

.form--profile .form__slide-content.open .form__item { opacity: 1; transition: all 0.3s ease; }

.form--profile .form__slide-content .form__item { opacity: 0; }

.form--profile .form__slide-content .form__item:last-child { padding-bottom: 30px; }

.form--profile .form__field { position: relative; }

.form--profile .form__field--multiple .form__input, .form--profile .form__field--multiple .form__textarea { padding: 0 5px; text-align: center; }

.form--profile .form__field--multiple .form__field-item:first-child .form__label { border-left: none; }

.form--profile .form__field--multiple .form__field-item:last-child .form__label { border-right: none; }

.form--profile .form__label, .form--profile .form__textarea { border: none; }

.form--profile .form__select .ss-single-selected, .form--profile .form__select .ss-multi-selected { border: none; }

.form--profile .form__caption { font-size: 0.875rem; color: rgba(16, 16, 16, 0.5); margin-bottom: 5px; }

.form--profile .form__link { color: rgba(16, 16, 16, 0.5); text-decoration: underline; font-size: 0.875rem; transition: all 0.3s ease; }

@media (hover: hover) { .form--profile .form__link:hover { text-decoration-color: transparent; } }

.form--profile .form__link:active { text-decoration-color: transparent; }

.form--profile .form__add-button { width: 100%; }

.form--profile .form__add-button .add-button { padding-left: 0; padding-right: 0; }

.form--profile .form__loader { width: 100%; transition: all 0.3s ease; display: flex; align-items: center; justify-content: center; flex-direction: column; border: 1px dashed rgba(87, 87, 87, 0.2); border-radius: 6px; padding: 25px; }

@media (hover: hover) { .form--profile .form__loader:hover { cursor: pointer; }
  .form--profile .form__loader:hover .form__loader-text { color: #4B5DFF; text-decoration-color: transparent; } }

.form--profile .form__loader-input { position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: 2; opacity: 0; cursor: pointer; }

.form--profile .form__loader-icon { display: flex; align-items: center; justify-content: center; margin-bottom: 10px; }

.form--profile .form__loader-icon svg { transition: all 0.3s ease; width: 22px; height: 22px; }

.form--profile .form__loader-text { transition: all 0.3s ease; font-size: 0.875rem; line-height: 1; text-decoration: underline; }

.form--profile .form__docs { display: flex; align-items: flex-start; }

.form--profile .form__docs-list { display: flex; align-items: flex-start; }

.form--profile .form__docs-list-item { display: flex; align-items: center; justify-content: center; flex-direction: column; margin-right: 30px; }

.form--profile .form__docs-list-item:last-child { margin-right: 0; }

.form--profile .form__docs-list-icon { box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1); }

.form--profile .form__docs-list-icon svg { width: 40px; height: 50px; }

.form--profile .form__docs-list-caption { max-width: 70px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; margin-top: 10px; color: rgba(16, 16, 16, 0.4); font-size: 0.875rem; }

.form--profile .form__docs-add-button { position: relative; margin-left: 30px; }

.form--profile .form__docs-add-button-input { position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: 2; opacity: 0; cursor: pointer; }

.form--profile .form__docs-add-button-icon svg { width: 40px; height: 50px; }

@media all and (max-width: 767px) { .form--profile .form__button { width: 100%; } }

.form--simple .form__field { flex-direction: column; align-items: flex-start; }

@media all and (max-width: 767px) { .form--simple .form__button { width: 100%; } }

.form--confirm .form__caption { text-align: center; line-height: 2; }

.form--confirm .form__link { text-align: center; line-height: 2; }

.form--confirm .form__field { align-items: center; }

.add-button { width: 100%; padding: 0 20px; height: 50px; transition: all 0.3s ease; display: flex; align-items: center; justify-content: center; border: 1px dashed rgba(87, 87, 87, 0.2); border-radius: 6px; }

@media (hover: hover) { .add-button:hover { cursor: pointer; }
  .add-button:hover .icon { background-color: rgba(75, 93, 255, 0.5); }
  .add-button:hover .icon svg { fill: #fff; } }

.add-button .icon { flex-shrink: 0; transition: all 0.3s ease; display: flex; align-items: center; justify-content: center; margin-right: 20px; width: 32px; height: 32px; border-radius: 50%; background-color: #fff; box-shadow: 0 6px 15px rgba(91, 96, 136, 0.2); }

.add-button .icon svg { transition: all 0.3s ease; width: 14px; height: 14px; fill: #4B5DFF; }

.open .add-button .icon svg { fill: red; }

.open .add-button .icon { background-color: #fff; transform: rotate(45deg); }

@media all and (max-width: 991px) { .add-button .icon { display: none; } }

.add-button .text { transition: all 0.3s ease; font-size: 1rem; line-height: 1; }

.modal { position: absolute; top: 0; left: 0; width: 100%; height: 100%; position: fixed; z-index: 300; display: none; }

.modal.is-open { display: block; }

.modal.is-open .modal__overlay { animation: modalFadeIn 0.3s cubic-bezier(0, 0, 0.2, 1) both; }

@keyframes modalFadeIn { from { opacity: 0; }
  to { opacity: 1; } }

.modal__overlay { width: 100%; height: 100%; background-color: rgba(16, 16, 16, 0.5); overflow: auto; }

.modal__inner { width: 100%; max-width: 1090px; padding: 0 15px; margin: 1em auto; }

@media all and (min-width: 992px) { .modal__inner { margin: 4em auto; } }

@media all and (min-width: 1600px) { .modal__inner { margin: 8em auto; } }

.modal__header { width: 100%; display: flex; align-items: center; flex-direction: column-reverse; justify-content: space-between; padding-bottom: 20px; }

@media all and (min-width: 992px) { .modal__header { flex-direction: row; } }

.modal__title { color: #fff; line-height: 1.1; text-align: center; }

@media all and (min-width: 992px) { .modal__title { text-align: left; } }

.modal__close { display: flex; align-items: center; justify-content: center; transition: all 0.3s ease; align-self: flex-end; }

.modal__close .icon { display: flex; align-items: center; justify-content: center; transition: all 0.3s ease; transform: rotate(45deg); color: #fff; line-height: 0; font-size: 2.1875rem; }

.modal__close svg { fill: #fff; width: 16px; height: 16px; }

@media (hover: hover) { .modal__close:hover { cursor: pointer; }
  .modal__close:hover .icon { color: #4B5DFF; } }

@media all and (min-width: 992px) { .modal__close { align-self: center; } }

.modal__content { width: 100%; background-color: #fff; box-shadow: 0 50px 100px rgba(0, 0, 0, 0.03); border-radius: 6px; padding: 25px 20px 35px 20px; }

.modal__content > div { margin-top: 0; }

@media all and (min-width: 768px) { .modal__content { padding: 40px 40px 100px 40px; } }

.modal__content .form--profile .form__label--text, .modal__content .form--profile .form__label--email { background-color: #F3F3F9; }

.modal__content .form--profile .form__label--select .ss-main .ss-single-selected { background-color: #F3F3F9; }

.modal--success .modal__overlay { display: flex; align-items: center; justify-content: center; }

.modal--success .modal__inner { width: 463px; margin: 0; }

.modal--success .modal__title { text-align: center; color: #101010; }

.modal--success .modal__text { margin-top: 10px; text-align: center; }

.modal--success .modal__icon { flex-shrink: 0; display: flex; align-items: center; justify-content: center; margin-bottom: 10px; width: 60px; height: 60px; border: 2px solid rgba(75, 93, 255, 0.1); border-radius: 50%; }

.modal--success .modal__icon-circle { width: 34px; height: 34px; display: flex; align-items: center; justify-content: center; border-radius: 50%; background-color: #4B5DFF; box-shadow: 0 6px 10px rgba(75, 93, 255, 0.25); }

.modal--success .modal__icon-circle svg { width: 14px; height: 14px; fill: #fff; }

.modal--success .modal__content { position: relative; padding-bottom: 50px; display: flex; align-items: center; justify-content: center; flex-direction: column; }

.modal--success .modal__close { position: absolute; z-index: 2; top: 20px; right: 20px; width: 12px; height: 12px; display: flex; align-items: center; justify-content: center; }

.modal--success .modal__close .icon { width: 100%; height: 100%; color: #575757; }

@media (hover: hover) { .modal--success .modal__close:hover { cursor: pointer; }
  .modal--success .modal__close:hover .icon { color: #4B5DFF; } }

.modal--success .modal__button { margin-top: 35px; }

.notify { position: fixed; z-index: 150; bottom: 0; right: 0; width: 100%; }

@media all and (min-width: 768px) { .notify { width: auto; bottom: 10px; right: 10px; } }

.alert { width: 100%; padding: 20px; overflow: hidden; margin: 2px 0 0 0; color: #fff; font-family: "OpenSansLight", sans-serif; background-color: #4B5DFF; border-radius: 0; }

@media all and (min-width: 768px) { .alert { width: 400px; padding: 20px 30px; border-radius: 4px; margin: 5px 0; } }

.js-customSelect { min-width: 90px; }

.js-customSelect .ss-single-selected { align-items: center; }

.js-customSelect .ss-single-selected .placeholder { display: inline; line-height: 1.3; }

.js-customSelect .ss-single-selected .ss-arrow span { margin: 0; }

.js-customSelect .ss-content .ss-list { max-height: 250px; }

.js-customSelect .ss-content .ss-list .simplebar-track.simplebar-vertical { width: 8px; }

.js-customSelect .ss-content .ss-list .simplebar-scrollbar:before { background-color: rgba(75, 93, 255, 0.2); opacity: 1; }

.datepicker-input { cursor: pointer; }

.spinner { position: absolute; top: 0; left: 0; width: 100%; height: 100%; display: flex; align-items: center; justify-content: center; transition: all 0.3s ease; z-index: 10; background-color: #fff; }

.spinner img { width: 30px; height: auto; }

.loaded .spinner { opacity: 0; visibility: hidden; }

/* rating - start */
.rating { width: 100%; }

.rating--line .rating__star { padding-right: 4px; }

.rating--static { pointer-events: none; }

.rating--small .rating__star { padding-right: 2px; }

.rating--small .rating__star svg { width: 10px; height: 10px; }

.rating__stars { display: flex; align-items: center; }

.rating__star { flex-shrink: 0; display: flex; align-items: center; justify-content: center; padding-right: 12px; }

.rating__star:last-child { margin-right: 0; }

.rating__star.tmp_fs svg, .rating__star.fullStar svg { fill: #FF8710; }

.rating__star.tmp_es svg { fill: #AEAEAE !important; }

.rating__star svg { transition: all 0.3s ease; fill: #AEAEAE; width: 20px; height: 20px; }

@media (hover: hover) { .rating__star { cursor: pointer; }
  .rating__star:hover svg { fill: #FF8710; } }

.rating-create__item { width: 100%; display: flex; align-items: center; margin-bottom: 15px; }

.rating-create__item--radio { margin-top: 25px; }

.rating-create__item:last-child { margin-bottom: 0; }

.rating-create__caption { flex-shrink: 0; width: 50%; font-size: 1rem; margin-right: 30px; }

.rating-create__radio .form__label { width: auto; margin-right: 20px; }

.rating-tooltip { position: absolute; z-index: 2; top: 100%; left: 0; width: auto; min-width: 400px; height: auto; background-color: #fff; box-shadow: 0 15px 35px rgba(0, 0, 0, 0.19); border-radius: 6px; overflow: hidden; margin-top: 4px; padding: 1em 2em 2em 2em; opacity: 0; visibility: hidden; }

.rating-tooltip.is-open { transition: opacity .3s, visibility .3s; opacity: 1; visibility: visible; }

.rating-tooltip__close { position: absolute; z-index: 2; top: 0; right: 0; width: 2em; height: 2em; display: flex; align-items: center; justify-content: center; }

.rating-tooltip__close svg { transition: all 0.3s ease; fill: #eeeeee; width: 0.8em; height: 0.8em; }

@media (hover: hover) { .rating-tooltip__close:hover { cursor: pointer; }
  .rating-tooltip__close:hover svg { fill: #4B5DFF; } }

.rating-tooltip__header { border-bottom: 1px solid #DCDCDC; padding-bottom: 1em; margin-bottom: 1em; }

.rating-tooltip__link { display: block; font-size: 0.75rem; color: #4B5DFF; text-decoration: underline; transition: all 0.3s ease; }

@media (hover: hover) { .rating-tooltip__link:hover { text-decoration-color: transparent; } }

.rating-tooltip__link:active { text-decoration-color: transparent; }

.rating-tooltip__title { font-size: 0.875rem; font-family: "OpenSansSemiBold", sans-serif; line-height: 1; margin-bottom: 10px; }

.rating-tooltip__button { border-color: #101010; font-size: 0.875rem; white-space: nowrap; padding: 10px 19px; }

.rating-tooltip .rating__star { flex-shrink: 1; padding-right: 5px; }

.rating-detail__title { font-size: 0.875rem; font-family: "OpenSansSemiBold", sans-serif; line-height: 1; margin-bottom: 1em; }

.rating-detail__list-item-caption { font-size: 0.75rem; }

.rating-detail__list-item-count { color: #AEAEAE; font-size: 0.75rem; line-height: 1; text-align: center; }

.rating-company { border-bottom: 1px solid #DCDCDC; padding-bottom: 1em; margin-bottom: 1em; }

.rating-company a { display: block; text-decoration: underline; transition: all 0.3s ease; }

@media (hover: hover) { .rating-company a:hover { text-decoration-color: transparent; } }

.rating-company a:active { text-decoration-color: transparent; }

.rating-company__info { margin-top: 0.3em; display: flex; align-items: center; }

.rating-company__info li { margin-right: 0.4em; }

.rating-company__info li:last-child { margin-right: 0; }

.rating-company__employee { font-size: 0.875rem; line-height: 1.2; margin-bottom: 6px; }

.rating-company__name { font-family: "OpenSansSemiBold", sans-serif; font-size: 0.875rem; }

.rating-company__city { color: #4B5DFF; font-size: 0.75rem; line-height: 1; }

.rating-company__id { color: rgba(16, 16, 16, 0.5); font-size: 0.75rem; line-height: 1; }

.rating-company__count { color: rgba(16, 16, 16, 0.5); font-size: 0.75rem; line-height: 1; }

/* rating - end */
.pagination { display: flex; }

.pagination__item { border: 1px solid #CFCFCF; border-radius: 6px; min-width: 40px; height: 40px; margin-right: 10px; }

@media all and (min-width: 768px) { .pagination__item { min-width: 46px; height: 46px; } }

.pagination__item--button .pagination__link { padding: 0 20px; }

.pagination__item.disabled { opacity: 0.5; pointer-events: none; }

.pagination__item.active { border-color: #101010; }

.pagination__item:last-child { margin-right: 0; }

.pagination__link, .pagination__text { width: 100%; height: 100%; display: flex; align-items: center; justify-content: center; text-align: center; padding: 0 10px; }

.card { position: relative; background-color: #FFFFFF; overflow: hidden; box-shadow: 0 15px 35px rgba(0, 0, 0, 0.19); border-radius: 6px; transition: all 0.3s ease; }

@media (hover: hover) { .card { overflow: visible; }
  .card:hover { box-shadow: 0 15px 35px rgba(0, 0, 0, 0.29); } }

.card.open .card-col { display: block; }

.card.open .card-col:nth-child(2), .card.open .card-col:nth-child(3) { width: 100%; border-bottom-color: #F1F1F1; }

.card.open .card-col:nth-child(n) .card__caption { display: block; }

.card.open .card-col:nth-child(n) .card__column { padding: 15px 40px; }

.card.open .card-col:nth-child(n) .card__column--route { padding-left: 15px; }

.card .card-col { display: none; border-bottom: 1px solid #F1F1F1; }

.card .card-col:nth-child(1), .card .card-col:nth-child(2), .card .card-col:nth-child(3) { display: block; }

.card .card-col:nth-child(1) { border-bottom: none; }

.card .card-col:nth-child(2), .card .card-col:nth-child(3) { width: 50%; border-bottom-color: transparent; }

.card .card-col:nth-child(2) .card__column, .card .card-col:nth-child(3) .card__column { padding: 15px; }

.card .card-col:nth-child(3) .card__column { padding-left: 0; }

@media all and (min-width: 992px) { .card .card-col { display: block; border-bottom: none; }
  .card .card-col:nth-child(2), .card .card-col:nth-child(3) { width: auto; }
  .card .card-col:nth-child(2) .card__column, .card .card-col:nth-child(3) .card__column { padding: 0; } }

.card__column { padding: 15px 40px; }

.card__column--company { background-color: #F8F8F8; padding-top: 10px; }

.card__column--route .card__caption { padding-left: 25px; }

@media all and (min-width: 992px) { .card__column { padding: 0; }
  .card__column--company { background: none; } }

.card__top { position: relative; width: 100%; padding: 0; font-size: 0.875rem; }

@media all and (min-width: 992px) { .card__top { padding: 35px 60px 10px 60px; border-bottom: 1px solid rgba(207, 207, 207, 0.5); } }

.card__bottom { width: 100%; padding: 15px 40px 20px 40px; font-size: 0.8125rem; display: none; }

.open .card__bottom { display: block; }

@media all and (min-width: 992px) { .card__bottom { padding: 20px; display: block; } }

.card__checkbox { position: absolute; z-index: 2; top: 10px; left: 10px; display: block; width: 20px; height: 20px; background-color: rgba(75, 93, 255, 0.1); border-radius: 3px; overflow: hidden; }

.card__checkbox-icon { display: flex; align-items: center; justify-content: center; position: absolute; top: 0; left: 0; width: 100%; height: 100%; transition: all 0.3s ease; z-index: 2; background-color: rgba(75, 93, 255, 0.1); opacity: 0; }

.card__checkbox-icon svg { width: 12px; height: 12px; fill: #fff; }

@media (hover: hover) { .card__checkbox:hover { cursor: pointer; }
  .card__checkbox:hover .card__checkbox-icon { opacity: 1; } }

.card__checkbox input[type="checkbox"] { position: absolute; opacity: 0; visibility: hidden; }

.card__checkbox input[type="checkbox"]:checked + .card__checkbox-icon { opacity: 1; background-color: #4B5DFF; }

@media all and (min-width: 992px) { .card__checkbox { top: 36px; left: 20px; } }

.card__button-wr { position: absolute; z-index: 2; top: 0; right: 10px; width: 20px; height: 100%; display: flex; align-items: center; flex-direction: column; padding-top: 1em; }

@media all and (min-width: 768px) { .card__button-wr { padding-top: 20px; } }

@media all and (min-width: 992px) { .card__button-wr { width: 60px; } }

.card__button { width: 16px; height: 16px; margin-bottom: 15px; }

.card__button:last-child { margin-bottom: 0; }

.card__button svg { width: 100%; height: 100%; fill: rgba(16, 16, 16, 0.5); }

@media (hover: hover) { .card__button:hover { cursor: pointer; } }

.card__button--message { width: 20px; height: 20px; z-index: 2; display: flex; align-items: center; justify-content: center; }

.card__button--message svg { width: 100%; height: 100%; fill: rgba(16, 16, 16, 0.5); }

.card__button--message svg path { transition: all 0.3s ease; }

@media (hover: hover) { .card__button--message:hover { cursor: pointer; }
  .card__button--message:hover svg mask path { fill: white; }
  .card__button--message:hover svg path { fill-opacity: 1; fill: #4B5DFF; } }

.card__button--remove { width: 12px; height: 12px; z-index: 2; display: flex; align-items: center; justify-content: center; }

.card__button--remove svg { width: 100%; height: 100%; fill: rgba(16, 16, 16, 0.5); transition: all 0.3s ease; }

@media (hover: hover) { .card__button--remove:hover { cursor: pointer; }
  .card__button--remove:hover svg { fill: red; } }

.card__button-more { padding: 10px; display: flex; align-items: center; justify-content: center; border-top: 1px solid #EDEDED; }

.card__button-more .icon { transition: all 0.3s ease; display: flex; align-items: center; justify-content: center; }

.card__button-more .icon svg { fill: #4B5DFF; width: 11px; height: 11px; }

.open .card__button-more .icon { transform: rotate(180deg); }

.card__button-more .card__payment-price { margin-right: auto; font-size: 0.875rem; }

.open .card__button-more .card__payment-price { display: none; }

.card__button-more .card__last-update { margin-right: 10px; margin-left: auto; font-size: 0.625rem; color: rgba(16, 16, 16, 0.5); }

.open .card__button-more .card__last-update { display: none; }

.card__caption { font-size: 0.875rem; color: rgba(16, 16, 16, 0.5); margin-bottom: 10px; display: none; }

.card__company-icon { position: absolute; z-index: 2; top: 40px; left: 10px; width: 18px; height: 18px; }

.card__company-item { display: flex; align-items: center; margin-bottom: 5px; }

.card__company-name { display: flex; align-items: center; }

.card__company-name .icon { flex-shrink: 0; display: flex; align-items: center; justify-content: center; margin-right: 10px; }

.card__company-name .icon svg { width: 18px; height: 18px; }

.card__company-name .text { font-size: 0.75rem; text-decoration: underline; transition: all 0.3s ease; }

@media (hover: hover) { .card__company-name .text:hover { text-decoration-color: transparent; } }

@media all and (min-width: 768px) { .card__company-name .text { font-size: 0.875rem; } }

@media all and (min-width: 992px) { .card__company-name .text { font-family: "OpenSansSemiBold", sans-serif; } }

.card__company-id { color: rgba(16, 16, 16, 0.5); font-size: 0.75rem; }

.card__company-rating { position: relative; margin-left: 7px; }

@media (hover: hover) { .card__company-rating:hover { cursor: pointer; } }

.card__company-factoring { display: flex; align-items: center; }

.card__company-factoring .icon { flex-shrink: 0; display: flex; align-items: center; justify-content: center; margin-right: 10px; }

.card__company-factoring .icon svg { width: 18px; height: 18px; fill: #4B5DFF; }

.card__company-factoring .text { color: #4B5DFF; }

.card__company-phone { color: #4B5DFF; transition: all 0.3s ease; font-size: 0.625rem; }

@media (hover: hover) { .card__company-phone:hover { color: #4B5DFF; text-decoration-color: transparent; } }

@media all and (min-width: 768px) { .card__company-phone { font-size: 0.875rem; } }

.card__company-employee--short-name { margin-right: 10px; font-size: 0.625rem; }

@media all and (min-width: 768px) { .card__company-employee--short-name { font-size: 0.875rem; } }

.card__route { position: relative; }

.card__route-date { padding-left: 25px; color: rgba(16, 16, 16, 0.5); font-size: 0.75rem; }

@media all and (min-width: 992px) { .card__route-date { position: absolute; width: 100%; bottom: 100%; left: 0; } }

.card__route-from, .card__route-to { display: flex; align-items: center; }

.card__route-icon { display: flex; align-items: center; justify-content: center; flex-shrink: 0; margin-right: 10px; }

.card__route-icon svg { width: 16px; height: 19px; }

.card__route-point { display: inline; font-family: "OpenSansSemiBold", sans-serif; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; }

.card__route-radius { border: 1px solid #AEAEAE; border-radius: 4px; font-size: 0.625rem; padding: 2px 6px; margin-left: 10px; white-space: nowrap; display: none; }

.open .card__route-radius { display: block; }

@media all and (min-width: 992px) { .card__route-radius { display: block; } }

.card__route-distance { margin: 4px 0; display: flex; align-items: center; }

.card__route-distance-icon { display: flex; align-items: center; justify-content: center; flex-shrink: 0; margin-right: 10px; }

.card__route-distance-icon svg { width: 16px; height: 27px; }

.card__route-distance-value { font-size: 0.625rem; }

.card__route-point-more { margin-top: 0.5em; padding-left: 25px; color: #4B5DFF; text-decoration: underline; transition: all 0.3s ease; font-size: 0.8125rem; }

@media (hover: hover) { .card__route-point-more:hover { cursor: pointer; text-decoration-color: transparent; } }

.card__cargo-properties { display: flex; flex-wrap: wrap; align-items: center; }

.card__cargo-properties-item { display: flex; align-items: center; justify-content: center; border: 1px solid #AEAEAE; border-radius: 4px; font-size: 0.75rem; padding: 2px 6px; margin-right: 8px; margin-bottom: 8px; }

.card__cargo-properties-item:last-child { margin-right: 0; }

.card__cargo-type { margin-bottom: 3px; font-size: 0.75rem; }

.open .card__cargo-type { font-family: "OpenSansSemiBold", sans-serif; font-size: 0.875rem; }

@media all and (min-width: 992px) { .card__cargo-type { font-family: "OpenSansSemiBold", sans-serif; font-size: 0.875rem; } }

.card__cargo-transport, .card__cargo-permission { margin-bottom: 5px; font-size: 0.75rem; }

.open .card__cargo-transport, .open .card__cargo-permission { font-family: "OpenSansSemiBold", sans-serif; font-size: 0.875rem; }

@media all and (min-width: 992px) { .card__cargo-transport, .card__cargo-permission { font-family: "OpenSansSemiBold", sans-serif; font-size: 0.875rem; } }

.card__cargo-temp { color: #4B5DFF; font-size: 0.8125rem; }

.card__transport-item { display: block; font-family: "OpenSansSemiBold", sans-serif; margin-bottom: 5px; }

.card__transport-item:last-child { margin-bottom: 0; }

.card__payment-price { font-family: "OpenSansBold", sans-serif; margin-bottom: 2px; }

.card__payment-rate { color: rgba(16, 16, 16, 0.5); font-size: 0.6875rem; margin-bottom: 5px; }

.card__payment-delay { display: flex; flex-direction: column; }

.card__payment-delay-title { color: rgba(16, 16, 16, 0.5); font-size: 0.6875rem; }

.card__info { color: rgba(16, 16, 16, 0.7); }

.card__comment { width: 100%; margin-bottom: 0; }

.card__comment-input { width: 100%; height: 46px; background-color: #F9F9F9; border-radius: 6px; padding: 0 20px; font-size: 0.8125rem; }

.cards--search .card__company-item:nth-child(n) { padding-left: 0; }

@media all and (min-width: 992px) { .cards--search .card__company-item:nth-child(n) { padding-left: 28px; }
  .cards--search .card__company-item:first-child { padding-left: 0; } }

.cards--cargos .card__column--company, .cards--transport .card__column--company { background: none; padding-left: 15px; }

@media all and (min-width: 992px) { .cards--cargos .card__column--company, .cards--transport .card__column--company { padding-left: 0; } }

.cards--cargos .card__company-phone, .cards--transport .card__company-phone { font-size: 0.75rem; }

@media all and (min-width: 768px) { .cards--cargos .card__company-phone, .cards--transport .card__company-phone { font-size: 0.875rem; } }

.cards--cargos .card__company-employee, .cards--transport .card__company-employee { font-family: "OpenSansSemiBold", sans-serif; }

.cards__table { width: 100%; margin-top: 30px; }

@media all and (min-width: 992px) { .cards__table { margin-top: 40px; } }

.cards__table-header { padding-left: 60px; padding-right: 60px; padding-bottom: 16px; }

.cards__table-title { font-size: 0.8125rem; color: rgba(16, 16, 16, 0.5); }

.cards__table-row { width: 100%; margin-bottom: 10px; }

.cards__table-row:last-child { margin-bottom: 0; }

.cards__nav { width: 100%; margin-top: 50px; }

.cards__btn { border: 1px solid #101010; border-radius: 6px; padding: 10px 20px; line-height: 1.2; display: flex; align-items: center; justify-content: center; transition: all 0.3s ease; }

@media (hover: hover) { .cards__btn:hover { cursor: pointer; border-color: #575757; background-color: #575757; color: #fff; } }

.cards__btn[disabled] { pointer-events: none; opacity: 0.3; }

@media all and (min-width: 768px) { .cards__btn { padding: 10px 30px; } }

.cards__create { width: 100%; margin-top: 100px; display: flex; align-items: center; justify-content: center; border: 1px dashed #575757; border-radius: 6px; padding: 76px 0; }

.cards__create-button { padding: 18px 43px; }

@media all and (max-width: 991px) { .cards__create { margin-top: 50px; } }

@media all and (max-width: 767px) { .cards__create { margin-top: 20px; padding: 20px; }
  .cards__create-button { width: 100%; } }

.cards__filter { display: flex; align-items: center; }

.cards__filter-caption { font-size: 0.8125rem; margin-right: 20px; }

.cards__filter-select { width: auto; }

.cards__filter-select .ss-single-selected { height: 40px; background-color: transparent; border: 1px solid #CFCFCF; color: #101010; border-radius: 6px; padding: 0 10px; }

@media all and (min-width: 768px) { .cards__filter-select .ss-single-selected { height: 46px; } }

.cards__filter-select .ss-single-selected .ss-arrow span { border-color: #101010; }

.cards__filter-select .ss-content { border-color: #CFCFCF; }

.cards__filter-select .ss-content .ss-list .ss-option { color: #101010; transition: all 0.3s ease; font-size: 0.875rem; padding: 10px; }

.cards__filter-select .ss-content .ss-list .ss-option:hover:not(.ss-disabled) { color: #101010; background-color: rgba(207, 207, 207, 0.2); }

.cards__filter-select .ss-content .ss-list .ss-option.ss-disabled { cursor: default; color: #101010; background-color: rgba(75, 93, 255, 0.1); }

@media all and (min-width: 992px) { .cards__pagination { margin-left: 50px; } }

/* home */
.home__search-tabs { width: 100%; }

.home__search-tabs-header-right { display: flex; align-items: center; justify-content: flex-end; }

.home__search-tabs-nav { width: 100%; display: flex; }

.home__search-tabs-nav-item { max-width: 50%; flex-grow: 1; font-size: 0.875rem; border-bottom-left-radius: 0; border-bottom-right-radius: 0; margin-right: 2px; padding: 1em; }

.home__search-tabs-nav-item:last-child { margin-right: 0; }

.home__search-tabs-nav-item.active { background-color: #fff; color: #101010; }

@media all and (min-width: 992px) { .home__search-tabs-nav-item { padding: 20px; margin-right: 3px; font-size: 1.125rem; } }

.home__search-tabs-content { width: 100%; background-color: #fff; box-shadow: 0 50px 100px rgba(0, 0, 0, 0.03); border-radius: 0 0 6px 6px; }

@media all and (min-width: 992px) { .home__search-tabs-content { background: none; box-shadow: none; border-radius: 0; } }

.home__search-tabs-content-item { width: 100%; display: none; }

.home__search-tabs-content-item.active { display: block; }

.home__search-tabs-button { margin-right: 8px; }

.home__search-tabs-button:last-child { margin-right: 0; }

.home__search-form { width: 100%; }

.home__search-form .form__label { position: relative; }

.home__search-form .form__input[type="number"], .home__search-form .form__textarea[type="number"] { text-align: center; }

.home__search-form .form__input.results-show, .home__search-form .results-show.form__textarea { border-bottom-left-radius: 0; border-bottom-right-radius: 0; }

.home__search-form-fileds { width: 100%; padding: 20px 20px 30px 20px; }

@media all and (min-width: 992px) { .home__search-form-fileds { padding: 46px 30px 30px 30px; background-color: #fff; box-shadow: 0 50px 100px rgba(0, 0, 0, 0.03); border-radius: 0 6px 0 0; } }

.home__search-form-route-results { position: absolute; width: 100%; height: 250px; left: 0; top: 100%; margin: -1px 0 0 0; border: solid 1px #dee2e6; border-top-color: #4B5DFF; z-index: 10; background-color: #fff; transform-origin: center top; transition: transform .2s, opacity .2s; opacity: 0; transform: scaleY(0); }

.home__search-form-route-results.results-show { opacity: 1; transform: scaleY(1); }

.home__search-form-route-results .simplebar-track.simplebar-vertical { width: 8px; }

.home__search-form-route-results .simplebar-scrollbar:before { background-color: rgba(75, 93, 255, 0.2); opacity: 1; }

.home__search-form-route-results-list { width: 100%; }

.home__search-form-route-results-list-item, .home__search-form-route-results-list .no_result { width: 100%; transition: all 0.3s ease; font-size: 0.875rem; padding: 14px 20px; }

.home__search-form-route-results-list-item:hover, .home__search-form-route-results-list .no_result:hover { cursor: pointer; background-color: rgba(207, 207, 207, 0.3); }

.home__search-form-route-results-list .no_result { pointer-events: none; }

.home__search-form-add-param { width: 100%; padding: 0 20px 30px 20px; border-radius: 0 0 6px 6px; background-color: #fff; transition: all 0.3s ease; }

.home__search-form-add-param.open { background-color: #F9F9F9; }

@media all and (max-width: 991px) { .home__search-form-add-param { border-radius: 0; }
  .home__search-form-add-param.open + .home__search-form-footer { background-color: #F9F9F9; } }

.home__search-form-add-param-btn { display: flex; align-items: center; }

@media (hover: hover) { .home__search-form-add-param-btn:hover { cursor: pointer; }
  .home__search-form-add-param-btn:hover .text { text-decoration-color: transparent; } }

.home__search-form-add-param-btn .icon { display: flex; align-items: center; justify-content: center; margin-right: 10px; }

.home__search-form-add-param-btn .icon svg { width: 20px; height: 20px; fill: #FF8710; }

.home__search-form-add-param-btn .text { text-decoration: underline; transition: all 0.3s ease; font-size: 0.875rem; }

@media all and (min-width: 992px) { .home__search-form-add-param-btn .text { font-size: 1rem; } }

.home__search-form-add-param-content { height: 0; overflow: hidden; }

.home__search-form-add-param-content.open { overflow: visible; }

.home__search-form-add-param-content-inner { width: 100%; height: 100%; padding: 20px 0 30px 0; }

.home__search-form-add-param-title { text-align: center; margin-bottom: 20px; }

@media all and (min-width: 992px) { .home__search-form-add-param-title { margin-bottom: 30px; } }

.home__search-form-add-param .form__label:nth-child(n), .home__search-form-add-param .form__field-item:nth-child(n) .form__label { border: none; }

.home__search-form-add-param .form__select .ss-single-selected { border: none; }

.home__search-form-add-param .form__select .ss-multi-selected { border: none; }

.home__search-form-footer { border-radius: 0 0 6px 6px; padding: 0 20px 40px 20px; transition: all 0.3s ease; }

@media all and (min-width: 992px) { .home__search-form-footer { padding: 30px 0 0 0; } }

.home__search-results { width: 100%; margin-top: 40px; }

@media all and (min-width: 992px) { .home__search-results { margin-top: 50px; } }

.home__search-results-title { text-align: center; margin-bottom: 0.5em; }

@media all and (min-width: 992px) { .home__search-results-title { text-align: left; margin-bottom: 0.3em; } }

.home__search-results-subtitle { font-size: 0.8125rem; color: rgba(16, 16, 16, 0.7); text-align: center; }

.home__search-results-subtitle span { text-decoration: underline; cursor: pointer; }

@media all and (min-width: 992px) { .home__search-results-subtitle { text-align: left; } }

/*profile */
.profile { margin-top: 23px; width: 100%; /* user page */ /* company page */ /* autopark page */ /* autopark create page */ /* drivers page */ /* managers page */ /* payment page */ /* reviews page */ /* edit page */ }

@media all and (min-width: 1200px) { .profile { background-color: #F3F3F9; box-shadow: 0 50px 100px rgba(0, 0, 0, 0.03); border-radius: 6px; overflow: hidden; } }

@media all and (max-width: 1199px) { .profile--autopark .profile__aside-inner, .profile--drivers .profile__aside-inner, .profile--managers .profile__aside-inner, .profile--payment .profile__aside-inner { padding: 0; } }

@media all and (max-width: 1199px) { .profile--autopark .profile__content, .profile--drivers .profile__content, .profile--managers .profile__content, .profile--payment .profile__content { background: none; box-shadow: none; border-radius: 0; padding: 0; } }

.profile__aside { width: 100%; height: 100%; }

.profile__aside-inner { width: 100%; padding: 0 0 1em 0; }

.profile__aside-inner .photo-loader { justify-content: center; }

@media all and (min-width: 1200px) { .profile__aside { background-color: rgba(255, 255, 255, 0.8); }
  .profile__aside-inner { padding: 40px 30px 30px 30px; } }

.profile__sidebar { width: 100%; padding: 0 0 100px 0; }

.profile__sidebar-item { width: 100%; display: flex; align-items: center; transition: all 0.3s ease; padding: 15px 20px; border-left: 4px solid transparent; font-size: 0.875rem; }

@media (hover: hover) { .profile__sidebar-item:hover { background-color: rgba(243, 243, 249, 0.5); } }

.profile__sidebar-item:active { background-color: #F3F3F9; }

.profile__sidebar-item.active { background-color: #F3F3F9; border-color: #4B5DFF; font-family: "OpenSansSemiBold", sans-serif; }

.profile__sidebar-item-icon { display: flex; align-items: center; justify-content: center; margin-right: 20px; }

.profile__sidebar-item-icon svg { width: 16px; height: 16px; }

@media all and (min-width: 1400px) { .profile__sidebar-item { padding: 25px 30px; font-size: 1rem; }
  .profile__sidebar-item-icon svg { width: 25px; height: 25px; } }

.profile__select { width: 100%; background-color: #fff; border-radius: 6px; overflow: hidden; }

.profile__select-header { display: flex; align-items: center; justify-content: space-between; padding: 1em; }

.profile__select-title { display: flex; align-items: center; }

.profile__select-title .icon { flex-shrink: 0; display: flex; align-items: center; justify-content: center; margin-right: 10px; }

.profile__select-title .icon svg { width: 16px; height: 16px; }

.profile__select-title .text { font-family: "OpenSansSemiBold", sans-serif; font-size: 0.875rem; }

.profile__select-icon { flex-shrink: 0; display: flex; align-items: center; justify-content: center; margin-left: 10px; transition: all 0.3s ease; }

.open .profile__select-icon { transform: rotate(180deg); }

.profile__select-icon svg { fill: #101010; width: 10px; height: 10px; }

.profile__select-content { height: 0; overflow: hidden; }

.profile__select-list { position: relative; padding: 0.5em 1em; width: 100%; }

.profile__select-list:before { content: ''; position: absolute; width: 100%; height: 1px; background-color: #f1f1f1; left: 0; top: 0; }

.profile__select-list-item { display: flex; align-items: center; padding: 1em 0; }

.profile__select-list-item.active { display: none; }

.profile__select-list-item-icon { flex-shrink: 0; display: flex; align-items: center; justify-content: center; margin-right: 10px; }

.profile__select-list-item-icon svg { width: 16px; height: 16px; }

.profile__select-list-item-text { font-size: 0.875rem; }

.profile__content { width: 100%; }

@media all and (min-width: 1200px) { .profile__content { padding: 40px 40px 40px 20px; } }

@media all and (max-width: 1199px) { .profile__content { padding: 20px; margin-top: 20px; background-color: #F3F3F9; box-shadow: 0 50px 100px rgba(0, 0, 0, 0.03); border-radius: 6px; overflow: hidden; } }

.profile__header { display: flex; align-items: center; justify-content: space-between; }

.profile__add-button { transition: all 0.3s ease; display: flex; align-items: center; justify-content: center; }

.profile__add-button .icon { flex-shrink: 0; display: flex; align-items: center; justify-content: center; margin-left: 10px; width: 32px; height: 32px; border-radius: 50%; background-color: #fff; box-shadow: 0 6px 15px rgba(91, 96, 136, 0.2); }

.profile__add-button .icon svg { transition: all 0.3s ease; width: 14px; height: 14px; fill: #4B5DFF; }

.open .profile__add-button .icon svg { fill: red; }

.profile__add-button .text { color: rgba(16, 16, 16, 0.5); font-size: 0.75rem; line-height: 1; }

@media all and (min-width: 768px) { .profile__add-button .text { font-size: 0.875rem; } }

.profile__title { font-size: 1.25rem; margin-bottom: 0; }

.profile__form { margin-top: 20px; }

@media all and (min-width: 992px) { .profile__form { margin-top: 40px; } }

.profile__photo { padding: 50px 40px; }

.profile__tabs { width: 100%; }

.profile__tabs-nav { width: 100%; display: flex; }

.profile__tabs-nav-item { display: flex; align-items: center; justify-content: center; transition: all 0.3s ease; max-width: 50%; flex-grow: 1; padding: 0; height: 50px; text-align: center; background-color: #fff; user-select: none; outline: none; border-right: 1px solid #E7EAF0; }

.profile__tabs-nav-item:first-child { border-radius: 6px 0 0 6px; }

.profile__tabs-nav-item:last-child { border-radius: 0 6px 6px 0; border-right: none; }

@media (hover: hover) { .profile__tabs-nav-item:hover { cursor: pointer; background-color: rgba(75, 93, 255, 0.1); } }

.profile__tabs-nav-item.active { border-color: transparent; background-color: #4B5DFF; color: #fff; }

@media all and (min-width: 992px) { .profile__tabs-nav-item { padding: 0 45px; flex-grow: 0; } }

.profile__tabs-content { width: 100%; margin-top: 30px; }

.profile__tabs-content-item { width: 100%; opacity: 0; display: none; }

.profile__tabs-content-item.active { display: block; animation: tabFadeIn .4s ease-in both; }

@keyframes tabFadeIn { to { opacity: 1; } }

.profile__table { width: 100%; }

.profile__table-header { width: 100%; padding-bottom: 10px; padding-right: 40px; }

.profile__table-body { width: 100%; }

.profile__table-row { position: relative; padding-right: 40px; background-color: #FFFFFF; border-radius: 6px; margin-bottom: 10px; overflow: hidden; transition: all 0.3s ease; }

@media (hover: hover) { .profile__table-row:hover { box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05); } }

.profile__table-row:last-child { margin-bottom: 0; }

.profile__table-row-header { transition: all 0.3s ease; padding-bottom: 20px; border-bottom: 1px solid transparent; }

.open .profile__table-row-header { margin-bottom: 20px; border-color: #EDEDED; }

.profile__table-row-header-info-item { margin-bottom: 0.5em; }

.profile__table-row-header-info-item:last-child { margin-bottom: 0; }

.profile__table-row-header .profile__table-img { position: static; width: 40px; height: 40px; border-radius: 2px; overflow: hidden; }

@media all and (max-width: 991px) { .profile__table-row-content { height: 0; overflow: hidden; } }

@media all and (max-width: 991px) { .profile__table-row { padding: 20px 20px 0 20px; } }

.profile__table-cell { position: relative; width: 100%; height: 100%; display: flex; flex-direction: column; justify-content: center; }

@media all and (min-width: 992px) { .profile__table-cell { min-height: 90px; padding: 14px 0; } }

.profile__table-title { color: rgba(16, 16, 16, 0.5); font-size: 0.625rem; }

@media all and (min-width: 1400px) { .profile__table-title { font-size: 0.875rem; } }

.profile__table-caption { color: rgba(16, 16, 16, 0.5); font-size: 0.875rem; margin-bottom: 5px; }

.profile__table-text { font-size: 0.875rem; }

@media all and (min-width: 1400px) { .profile__table-text { font-size: 1rem; } }

.profile__table-link { transition: all 0.3s ease; }

@media (hover: hover) { .profile__table-link:hover { color: #4B5DFF; } }

.profile__table-link:active { color: #4B5DFF; }

.profile__table-img { position: absolute; top: 0; left: 0; width: 100%; height: 100%; background-size: cover; background-position: center center; background-repeat: no-repeat; }

.profile__table-btn-wr { position: absolute; z-index: 2; top: 20px; right: 20px; width: auto; height: auto; display: flex; align-items: center; }

@media all and (min-width: 992px) { .profile__table-btn-wr { top: 0; right: 0; width: 40px; padding: 10px 0; height: 100%; flex-direction: column; justify-content: space-between; } }

.profile__table-btn { flex-shrink: 0; margin-right: 1em; display: flex; align-items: center; justify-content: center; }

.profile__table-btn svg { width: 14px; height: 14px; transition: all 0.3s ease; fill: rgba(90, 90, 90, 0.5); }

.profile__table-btn:last-child { margin-right: 0; }

@media (hover: hover) { .profile__table-btn:hover { cursor: pointer; } }

@media (hover: hover) { .profile__table-btn--remove:hover svg { fill: #FF8710; } }

.profile__table-btn--remove svg { width: 12px; height: 12px; }

@media (hover: hover) { .profile__table-btn--edit:hover svg, .profile__table-btn--copy:hover svg { fill: #575757; } }

.profile__table-btn--edit svg, .profile__table-btn--copy svg { fill: #4B5DFF; }

@media all and (min-width: 992px) { .profile__table-btn { margin-right: 0; } }

.profile__table-btn-more { width: 100%; border-top: 1px solid #EDEDED; display: flex; align-items: center; justify-content: center; }

.profile__table-btn-more .icon { margin: 6px 0; display: flex; align-items: center; justify-content: center; transition: all 0.3s ease; }

.profile__table-btn-more .icon svg { fill: #4B5DFF; width: 12px; height: 12px; }

.open .profile__table-btn-more .icon { transform: rotate(180deg); }

.profile-user { width: 100%; }

.profile-user__photo { display: flex; justify-content: flex-start; }

.profile-user__form { margin-top: 20px; }

@media all and (min-width: 992px) { .profile-user__form { margin-top: 35px; } }

.profile-company { width: 100%; margin-top: 20px; }

@media all and (min-width: 992px) { .profile-company { margin-top: 40px; } }

.profile-company__title { margin-bottom: 8px; }

.profile-company__table { width: 100%; }

.profile-company__table-header { padding: 10px 20px 10px 20px; }

.profile-company__table-row { position: relative; width: 100%; padding: 15px 20px; border-radius: 6px; background-color: #fff; border: 2px solid #4B5DFF; }

.profile-company__table-cell { display: flex; align-items: center; }

.profile-company__table-title { color: rgba(16, 16, 16, 0.5); font-size: 0.875rem; }

.profile-company__table-text { font-size: 0.875rem; }

@media all and (min-width: 1400px) { .profile-company__table-text { font-size: 1rem; } }

.profile-company__table-caption { color: rgba(16, 16, 16, 0.5); font-size: 0.875rem; margin-bottom: 10px; }

.profile-company__table-name { display: flex; }

.profile-company__table-name .icon { flex-shrink: 0; display: flex; align-items: center; justify-content: center; margin-right: 10px; margin-top: 2px; }

.profile-company__table-name .icon svg { width: 18px; height: 18px; }

.profile-company__table-name .text { font-size: 0.875rem; }

@media all and (min-width: 1400px) { .profile-company__table-name .text { font-size: 1rem; } }

.profile-company__table-comment { flex-grow: 1; height: 58px; padding: 10px 20px; background: #F9F9F9; border-radius: 6px; font-size: 0.875rem; }

.profile-company__table-comment-inner { width: 100%; height: 100%; padding-right: 15px; }

.profile-company__table-comment .simplebar-track.simplebar-vertical { width: 2px; background-color: #ececec; border-radius: 2px; }

.profile-company__table-comment .simplebar-scrollbar:before { top: 0; left: 0; width: 100%; right: auto; background-color: #4b5dff; opacity: 1; border-radius: 2px; }

.profile-company__table-btn-wr { margin-left: 20px; display: flex; align-items: center; }

@media all and (max-width: 991px) { .profile-company__table-btn-wr { position: absolute; top: 15px; right: 20px; margin: 0; } }

.profile-company__table-btn { display: flex; align-items: center; justify-content: center; margin-right: 20px; }

.profile-company__table-btn:last-child { margin-right: 0; }

.profile-company__table-btn svg { transition: all 0.3s ease; fill: rgba(90, 90, 90, 0.5); }

@media (hover: hover) { .profile-company__table-btn--remove { cursor: pointer; }
  .profile-company__table-btn--remove:hover svg { fill: #FF8710; } }

.profile-company__table-btn--remove svg { width: 12px; height: 12px; }

@media (hover: hover) { .profile-company__table-btn--edit { cursor: pointer; }
  .profile-company__table-btn--edit:hover svg { fill: #575757; } }

.profile-company__table-btn--edit svg { width: 14px; height: 14px; fill: #4B5DFF; }

@media all and (max-width: 991px) { .profile-company__table-btn { margin-right: 15px; } }

.profile-company__main { width: 100%; }

.profile-company__sub { width: 100%; margin-top: 20px; }

@media all and (min-width: 992px) { .profile-company__sub { margin-top: 40px; } }

@media all and (min-width: 992px) { .profile-company__sub-inner { padding: 5px 20px 20px 20px; background-color: #fff; border-radius: 6px; } }

.profile-company__sub .profile-company__table-row { background-color: #fff; border: none; }

@media all and (min-width: 992px) { .profile-company__sub .profile-company__table-row { background-color: rgba(243, 243, 249, 0.5); } }

@media all and (min-width: 992px) { .profile-company__sub .profile-company__table-comment { background-color: #fff; } }

.profile-company__add-button { display: block; margin-top: 20px; }

.profile-company__add-button .add-button { padding-left: 0; padding-right: 0; }

.profile-autopark { width: 100%; margin-top: 20px; }

@media all and (min-width: 992px) { .profile-autopark { margin-top: 30px; } }

.profile-autopark__add-button { display: block; width: 100%; }

.profile-autopark__add-button .add-button .text { text-decoration: underline; }

.profile-autopark__tabs { width: 100%; }

.profile-autopark-create { width: 100%; margin-top: 30px; }

.profile-autopark-create .add-button { justify-content: flex-start; border: none; }

.profile-autopark-create .add-button .icon { display: flex; }

.profile-drivers { width: 100%; margin-top: 20px; }

@media all and (min-width: 992px) { .profile-drivers { margin-top: 30px; } }

.profile-drivers__add-button { width: 100%; display: block; }

.profile-drivers__add-button .add-button .text { text-decoration: underline; }

.profile-drivers__tabs { width: 100%; }

.profile-managers { width: 100%; margin-top: 20px; }

@media all and (min-width: 992px) { .profile-managers { margin-top: 30px; } }

.profile-managers__add-button { display: block; width: 100%; }

.profile-managers__add-button .add-button .text { text-decoration: underline; }

.profile-managers__tabs { width: 100%; }

.profile-payment { width: 100%; margin-top: 30px; }

.profile-payment__tabs { width: 100%; }

@media all and (max-width: 991px) { .profile-payment__tabs .profile__tabs-nav-item { border-radius: 6px 6px 0 0; } }

@media all and (max-width: 991px) { .profile-payment__tabs .profile__tabs-content { margin-top: 0; padding: 20px; background: #F3F3F9; box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1); border-radius: 0 0 6px 6px; } }

.profile-payment__tabs .profile__table-header { padding-left: 20px; }

.profile-payment__tabs .profile__table-row { padding-left: 20px; }

.profile-payment__form .form__item { margin-bottom: 40px; }

.profile-payment__form .form__label { background: none; height: auto; border-radius: 0; }

.profile-payment__form .form__label--checkbox { margin-bottom: 12px; }

.profile-payment__form .form__label--info { padding-left: 35px; }

.profile-payment__form .form__info { color: rgba(16, 16, 16, 0.5); font-size: 0.875rem; margin: 0; }

.profile-payment__form .form__checkbox:checked + .form__checkbox-icon { background-color: #4B5DFF; }

.profile-payment__form .form__checkbox-icon { transition: all 0.3s ease; background-color: #fff; }

.profile-payment__form .form__checkbox-icon svg { width: 12px; height: 12px; fill: #fff; }

.profile-payment__form .form__checkbox-caption { margin-left: 15px; }

.profile-reviews { width: 100%; margin-top: 30px; }

.profile-edit { width: 100%; margin-top: 30px; }

.profile-edit__photo { display: flex; justify-content: flex-start; }

.profile-edit__form { margin-top: 20px; }

@media all and (min-width: 992px) { .profile-edit__form { margin-top: 40px; } }

@media all and (max-width: 767px) { .profile-edit__form .form__field { flex-direction: column; } }

/* auth */
.auth { width: 100%; margin: 0 auto; }

.auth__title { margin-bottom: 20px; }

.auth__form { width: 100%; background-color: #fff; box-shadow: 0 50px 100px rgba(0, 0, 0, 0.03); border-radius: 6px; padding: 50px 40px; }

@media all and (max-width: 767px) { .auth__form { padding: 20px; }
  .auth__form .form__link { width: 100%; font-size: 0.875rem; text-align: center; } }

/* review */
.review-card { width: 100%; background-color: #fff; border-radius: 6px; margin-bottom: 10px; padding: 20px 30px 30px 30px; }

.review-card:last-child { margin-bottom: 0; }

.review-card--view { box-shadow: 0 15px 35px rgba(0, 0, 0, 0.19); }

.review-card__title, .review-card__date { font-size: 0.875rem; color: rgba(16, 16, 16, 0.4); line-height: 1; }

.review-card__rating .rating__star { padding-right: 5px; }

.review-card__btn:nth-child(odd) { color: #575757; font-size: 0.875rem; padding: 9px 22px; border-color: #575757; }

.review-card__btn:nth-child(even) { font-size: 0.875rem; padding: 10px 22px; }

.review-card__content.open .review-card__content-btn .icon { transform: rotate(180deg); }

.review-card__content-preview { line-height: 1.5; }

.review-card__content-full { height: 0; overflow: hidden; line-height: 1.5; }

.review-card__content-btn { display: flex; align-items: center; margin-top: 1.5em; font-size: 0.875rem; color: #4B5DFF; line-height: 1; }

.review-card__content-btn .icon { transition: all 0.3s ease; display: flex; align-items: center; justify-content: center; margin-left: 10px; }

.review-card__content-btn .icon svg { width: 10px; height: 10px; fill: #4B5DFF; }

@media (hover: hover) { .review-card__content-btn:hover { cursor: pointer; } }

.review-card__content .rating-detail { margin-top: 0.8em; margin-bottom: 1em; }

.review-card__content .rating-detail__list-item-caption { color: #AEAEAE; }

.review-card__comment.open .review-card__comment-btn { color: #101010; }

.review-card__comment-btn { display: flex; align-items: center; margin-top: 1em; font-size: 0.875rem; color: #7F7F7F; line-height: 1; text-decoration: underline; transition: all 0.3s ease; }

@media (hover: hover) { .review-card__comment-btn:hover { cursor: pointer; text-decoration-color: transparent; } }

.review-card__comment-name { font-size: 0.875rem; color: rgba(16, 16, 16, 0.3); margin-bottom: 0.5em; }

.review-card__comment-list { height: 0; overflow: hidden; }

.review-card__comment-list-item { padding: 1em; background-color: #FAFAFA; border-bottom: 1px solid #DFDFDF; font-size: 0.875rem; }

.review-card__comment-list-item:first-child { margin-top: 20px; border-radius: 6px 6px 0 0; }

.review-card__comment-list-item:last-child { border-bottom: none; border-radius: 0 0 6px 6px; }

.review-card__recommendation { display: flex; align-items: center; font-size: 0.875rem; }

.review-card__recommendation .icon { flex-shrink: 0; display: flex; align-items: center; justify-content: center; margin-right: 10px; }

.review-card__recommendation .icon svg { fill: #4B5DFF; width: 20px; height: 20px; }

.review-card .rating-company__name, .review-card .rating-company__info { display: inline-flex; }

.review-card .rating-company__info { margin-left: 4px; }

/* reviews */
.reviews { margin-top: 20px; width: 100%; /* create review page */ }

.reviews-create { width: 100%; background-color: #FFFFFF; box-shadow: 0 15px 35px rgba(0, 0, 0, 0.19); border-radius: 6px; padding: 30px 30px 40px 30px; }

.reviews-create__header { width: 100%; }

.reviews-create__header .rating-company { padding-bottom: 20px; margin-bottom: 20px; }

.reviews-create__title { font-size: 1.125rem; margin-bottom: 20px; }

.reviews-create__subtitle { font-size: 1rem; font-family: "OpenSansSemiBold", sans-serif; margin-bottom: 20px; }

.reviews-create__form { display: flex; flex-wrap: wrap; justify-content: space-between; }

.reviews-create__form-left, .reviews-create__form-right { width: 47%; display: flex; flex-direction: column; }

@media all and (max-width: 991px) { .reviews-create__form-left, .reviews-create__form-right { width: 100%; } }

.reviews-create__form-footer { margin-top: 30px; width: 100%; display: flex; flex-wrap: wrap; align-items: center; justify-content: space-between; }

.reviews-create__form-title { font-size: 1rem; margin-bottom: 20px; font-family: "OpenSansSemiBold", sans-serif; }

.reviews-create__form-label { margin-bottom: 15px; }

.reviews-create__form-label .form__caption { font-size: 0.875rem; margin-bottom: 5px; }

.reviews-create__form-label--text .form__label { border: none; background-color: rgba(238, 238, 238, 0.7); }

.reviews-create__form-label--text .form__select .ss-single-selected, .reviews-create__form-label--text .form__select .ss-multi-selected { background-color: transparent; border: none; }

.reviews-create__form-label--textarea .form__textarea { border: none; background-color: rgba(238, 238, 238, 0.7); }

.reviews-create__form-label:last-child { margin-bottom: 0; }

.reviews-create__form-button { margin-right: 10px; }

.reviews-create__form-button:last-child { margin-right: 0; }

.reviews-create__form-button:not([type="submit"]) { padding: 15px 35px; }

.reviews-create__form-button[type="submit"] { padding: 15px 35px; }

.reviews-create__form-button-group { width: 47%; }

@media all and (max-width: 991px) { .reviews-create__form-button-group { width: 100%; } }

.reviews-create__form-link { color: rgba(16, 16, 16, 0.4); text-decoration: underline; transition: all 0.3s ease; margin-right: 40px; }

.reviews-create__form-link:last-child { margin-right: 0; }

@media (hover: hover) { .reviews-create__form-link:hover { color: #4B5DFF; text-decoration-color: transparent; } }

.reviews-create__form-link:active { color: #4B5DFF; text-decoration-color: transparent; }

.reviews-create__form-link-group { width: 47%; }

@media all and (max-width: 991px) { .reviews-create__form-link-group { width: 100%; } }

.reviews-sort__caption { color: rgba(16, 16, 16, 0.4); font-size: 0.875rem; margin-right: 1em; }

.reviews-sort__select { min-width: 1px; }

.reviews-sort__select-label { margin-bottom: 0; }

.reviews-sort__select .ss-single-selected, .reviews-sort__select .ss-multi-selected { background-color: transparent; border-color: transparent; color: rgba(16, 16, 16, 0.4); font-size: 0.875rem; }

.reviews-sort__select .ss-single-selected .ss-arrow, .reviews-sort__select .ss-multi-selected .ss-arrow { margin-left: 0.6em; }

.reviews-sort__select .ss-single-selected .ss-arrow span, .reviews-sort__select .ss-multi-selected .ss-arrow span { border-color: #878787; }

.reviews-info { width: 100%; }

.reviews-info.fixed { position: fixed; z-index: 100; top: 0; left: 0; background-color: #fff; padding: 2em 2em 1em 2em; box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1); }

.reviews-info__header { border-bottom: 1px solid #DCDCDC; padding-bottom: 2em; margin-bottom: 1em; }

.reviews-info__header .rating-detail__list-item { margin-bottom: 0.2em; }

.reviews-info__header .rating-detail__list-item > .row { flex-direction: row-reverse; justify-content: center; }

.reviews-info__header .rating-company { border: none; margin: 0; padding: 0; }

.reviews-info__header .rating-company__name { font-size: 1.125rem; }

.reviews-info__header .rating-company__city, .reviews-info__header .rating-company__id { font-size: 0.875rem; }

/* 404 */
.error-box { display: flex; align-items: center; justify-content: center; flex-direction: column; }

.error-box__title { text-align: center; color: #4B5DFF; font-size: 7.5rem; line-height: 1; font-family: "RubikMedium", sans-serif; margin-bottom: 20px; }

@media all and (max-width: 767px) { .error-box__title { font-size: 3.125rem; } }

.error-box__text { color: #4B5DFF; text-align: center; font-size: 1.75rem; line-height: 1; font-family: "RubikLight", sans-serif; }

@media all and (max-width: 767px) { .error-box__text { font-size: 1rem; } }
