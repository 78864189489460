@mixin pseudo($display: block, $pos: absolute, $content: ''){
	content: $content;
	display: $display;
	position: $pos;
}
/*---------------pseudo element default styles---------------*/

@mixin pseudo-wrapper($width: 100%, $height: 100%, $content: '') {
	content: $content;
	position: absolute;
	display: block;
	left: 0;
	top: 0;
	width: $width;
	height: $height;
}
/*---------------------pseudo full size---------------------*/

@mixin responsive-ratio($x,$y, $pseudo: false) {
	$padding: unquote( ( $y / $x ) * 100 + '%' );
	@if $pseudo { 
		&:before {
			@include pseudo($pos: relative);
			width: 100%;
			padding-top: $padding;
		}
	} @else {
		padding-top: $padding;
	}
}
/*--------------------proportional ratio--------------------*/

@mixin placeholder {
	&.placeholder { @content; }
	&:-moz-placeholder { @content; }
	&::-moz-placeholder { @content; }
	&:-ms-input-placeholder { @content; }
	&::-webkit-input-placeholder { @content; }
}
/*--------------------placeholder styles--------------------*/

@mixin clearfix {
	&:after {
		content: "";
		display: table;
		clear: both;
	}
}
/*-------------------------clearfix-------------------------*/

@mixin transition($what: all, $when: .3s, $how: ease) {
	transition: $what $when $how;
}
/*------------------------transition------------------------*/

@mixin background($path, $position: center center, $repeat: no-repeat, $size: cover) {
	background: {
		image: url($path);
		position: $position;
		repeat: $repeat;
		size: $size;
	}
}
/*--------------------background image--------------------*/

@mixin center($position) {
	position: absolute;
	
	@if $position == 'vertical' {
		top: 50%;
		-webkit-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		transform: translateY(-50%);
	}
	@else if $position == 'horizontal' {
		left: 50%;
		-webkit-transform: translateX(-50%);
		-ms-transform: translateX(-50%);
		transform: translate(-50%);
	}
	@else if $position == 'both' {
		top: 50%;
		left: 50%;
		-webkit-transform: translate(-50%, -50%);
		-ms-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
	}
}
/*--------------------absolute alignment--------------------*/

@mixin keyframes($animation-name) {
	@-webkit-keyframes #{$animation-name} {
		@content;
	}
	@-moz-keyframes #{$animation-name} {
		@content;
	}  
	@-ms-keyframes #{$animation-name} {
		@content;
	}
	@-o-keyframes #{$animation-name} {
		@content;
	}  
	@keyframes #{$animation-name} {
		@content;
	}
}

@mixin animation($str) {
	-webkit-animation: #{$str};
	-moz-animation: #{$str};
	-ms-animation: #{$str};
	-o-animation: #{$str};
	animation: #{$str};      
}
/*------------------------animation------------------------*/

@mixin triangle($direction, $size: 6px, $color: #222){
	content: '';
	display: block;
	position: absolute;
	height: 0; width: 0;
	@if ($direction == 'up'){
		border-bottom: $size solid $color;
		border-left: 1/2*$size solid transparent;
		border-right: 1/2*$size solid transparent;
	}
	@else if ($direction == 'down'){
		border-top: $size solid $color;
		border-left: 1/2*$size solid transparent;
		border-right: 1/2*$size solid transparent;
	}
	@else if ($direction == 'left'){
		border-top: 1/2*$size solid transparent;
		border-bottom: 1/2*$size solid transparent;
		border-right: $size solid $color;
	}
	@else if ($direction == 'right'){
		border-top: 1/2*$size solid transparent;
		border-bottom: 1/2*$size solid transparent;
		border-left: $size solid $color;
	}
}
/*------------------make triangle------------------*/

@mixin font-face($font-name, $file-name, $weight: normal, $style: normal) { 
	@font-face {
		font-family: quote($font-name);
		src: url($file-name + '.woff2') format('woff2'),
		url($file-name + '.woff') format('woff'),
		url($file-name + '.ttf')  format('truetype');
		font-weight: $weight;
		font-style: $style;
	}
}
/*---------------------------fonts---------------------------*/

@mixin forIE() { 
	@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) { 
		@content; 
	} 
} 
@mixin forFF() { 
	@-moz-document url-prefix() { 
		@content; 
	} 
}
/*-----------------------for browsers-----------------------*/

@function get($map, $keys...) {
	@each $key in $keys {
		$map: map-get($map, $key);
	}
	@return $map;
}
/*-----------------------map get recursive-----------------------*/

@mixin setFontSize($px, $rfs: false, $px-to-rem: true) {
  @if($rfs) {
    @include responsive-font-size($px);
  }
  @else if($px-to-rem) {
    font-size: rem($px * 1px);
  }
  @else {
    font-size: #{$px}px;
  }
}
/*-----------------------font size-----------------------*/

@mixin formNormalize() {
  input[type=text], input[type=submit], input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-buttontextarea, select {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    margin: 0;
  }
  input[type=number], input[type=text], input[type=submit], button, select {
    -moz-appearance: textfield;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    transition: 9999999s ease-in-out 0s;
  }
  input:invalid {
    box-shadow: none;
  }
}

@mixin defaultField() {
  width: 100%;
  border: 0;
  background: none;
  outline: none;
  padding: 0;
  margin: 0;
  @include transition();

  &:invalid, .invalid & {
    color: #ff0000;
  }
  &:focus {
  }
  &::-webkit-input-placeholder {
    color: rgba(#2f2f2f, 0.5);
    font-family: $qan-reg;
    @include transition();
  }
  &::-moz-input-placeholder {
    @include transition();
  }
  &[disabled] {
    pointer-events: none !important;
    opacity: 0.3 !important;
    cursor: default !important;
  }
  &[readonly] {
    cursor: default !important;
  }
}

@mixin fullSize() {
  width: 100%;
  height: 100%;
}

@mixin fullSizePos() {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@mixin bgCover() {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

@mixin flexCenter() {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flexCenterCol() {
  @include flexCenter();
  flex-direction: column;
}
