@import 'utilites/reboot';
@import 'utilites/rem';
@import 'utilites/variables';
@import 'utilites/media-queries';
@import 'utilites/mixins';
@import 'node_modules/bootstrap/scss/vendor/rfs';

* {
  outline: none;
}
html,
body {
  height: 100%;
}
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: auto;
  font-family: $sans-reg;
  color: $body-color;
  line-height: 1.3;
  font-size: 16px;
  cursor: default;
  position: relative;
  -webkit-overflow-scrolling: touch;

  &.modal-is-open, &.menu-is-open {
    overflow: hidden;

    @include mediaToUp($xl) {
      padding-right: 1em;
    }
  }
  &.is-blocked {
    pointer-events: none;
  }
}
h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
  font-family: $rubik-reg;
}
ul, ol, li {
  margin: 0;
  padding: 0;
  list-style: none;
}
button {
  border: none;
  padding: 0;
  margin: 0;
  background-color: transparent;
  cursor: pointer;

  &[type="button"], &[type="submit"] {
    &:focus {
      outline: none;
    }
  }
}
a {
  color: inherit;
  text-decoration: none;

  @media (hover: hover) {
    &:hover {
      color: inherit;
    }
  }
  &:hover, &:active {
    color: inherit;
  }
}
input[type='number'] {
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
input, textarea {
  &:focus {
    outline: none;
  }
}
svg {
  max-width: 100%;
  max-height: 100%;
}
img {
  max-width: 100%;
}
/* for extends */
%title {
  font-family: $rubik-reg;
  line-height: 1.1;

  &--large {
    @extend %title;
    @include setFontSize(get($font-sizes, middle-up-title));

    @include mediaToUp($lg) {
      @include setFontSize(get($font-sizes, large));
    }
  }
}
%button {
  display: inline-block;
  border: none;
  user-select: none;
  box-shadow: none;
  border-radius: 6px;
  text-align: center;
  line-height: 1;
  padding: 13px 30px;
  @include transition();
  @include setFontSize(get($font-sizes, small));

  @media (hover: hover) {
    &:hover {
      cursor: pointer;
    }
  }
  &:active {
  }
  @include mediaToUp($lg) {
    @include setFontSize(get($font-sizes, default));
    padding: 13px 50px;
  }
  &--border {
    @extend %button;
    border: 1px solid #CFCFCF;
  }
  &--gray {
    @extend %button;
    background-color: get($colors, btn-gray);
    color: #fff;

    @media (hover: hover) {
      &:hover {
        color: #fff;
        background-color: get($colors, btn-gray-hover);
      }
    }
    &:active {
    }
  }
  &--form {
    @extend %button;
    padding: 15px 30px;
    box-shadow: 0 6px 10px rgba(87, 87, 87, 0.15);

    @include mediaToUp($lg) {
      padding: 17px 34px;
    }
  }
}
/* common */
.page {
  position: relative;
  width: 100%;
  height: auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: get($colors, gray);

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__title {
    @extend %title--large;
  }
  .content {
    padding-top: 80px;
    padding-bottom: 100px;
    width: 100%;
    flex: 1 0 auto;

    @include mediaToUp($lg) {
      padding-top: 40px;
    }
  }
  .header, .footer {
    flex: 0 0 auto;
  }
  &--profile, &--auth {
    background-color: get($colors, lilac);
  }
  &--full {
    .content {
      display: flex;

      .container {
        flex: 1 0 auto;
        @include flexCenterCol();
      }
    }
  }
}
.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 130;
  width: 100%;
  height: 50px;
  background-color: #fff;

  .menu-is-open & {
    .header {
      &__top {
        box-shadow: none;
        border-bottom-color: #F1F1F1;

        .header__login {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
  @include mediaToUp($lg) {
    position: static;
    height: 60px;
  }
  @include mediaToUp($vl) {
    height: auto;
  }
  &__top {
    height: 100%;
    padding-top: 0;
    padding-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid transparent;
    @include transition();

    @include mediaToUp($lg) {
      padding-top: 10px;
      padding-bottom: 15px;
    }
    @include mediaToUp($vl) {
      height: auto;
    }
    &-left {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .header__login {
        @include fullSizePos();
        @include transition();
        opacity: 0;
        visibility: hidden;
        z-index: 2;
        background-color: #fff;
        margin: 0;
      }
    }
    &-right {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
  &__bottom {
    width: 100%;
    background-color: #F9F9F9;
  }
  &__logo {
    flex-shrink: 0;
    @include flexCenter();

    img {
      width: auto;
      height: 30px;
    }
    @include mediaToUp($lg) {
      margin-right: 50px;

      img {
        width: 120px;
        height: auto;
      }
    }
  }
  &__menu {
    flex-grow: 1;
  }
  &__phone {
    @include transition();

    @media (hover: hover) {
      &:hover {
        color: get($colors, purple);
      }
    }
    &:active {
      color: get($colors, purple);
    }
  }
  &__login {
    position: relative;
    margin-left: 70px;
    display: flex;
    align-items: center;

    &:hover {
      .header__login-exit {
        opacity: 1;
        visibility: visible;
      }
    }
    &-icon {
      flex-shrink: 0;
      @include flexCenter();
      margin-right: 10px;

      svg {
        fill: get($colors, purple);
        width: 17px;
        height: 17px;
      }
    }
    &-separator {
      margin: 0 4px;
    }
    &-user {
      position: relative;
      z-index: 5;
      padding: 10px;
      display: flex;
      align-items: center;

      &-icon {
        @include flexCenter();
        margin-right: 10px;

        svg {
          fill: rgba(get($colors, black), 0.7);;
          width: 17px;
          height: 17px;
          @include transition();
        }
      }
      &-text {
        font-family: $sans-semibold;
        color: rgba(get($colors, black), 0.7);
        @include transition();
      }
      @media (hover: hover) {
        &:hover {
          .header__login-user {
            &-icon {
              svg {
                fill: get($colors, purple);
              }
            }
            &-text {
              color: get($colors, purple);
            }
          }
        }
      }
      &:active {
        .header__login-user {
          &-icon {
            svg {
              fill: get($colors, purple);
            }
          }
          &-text {
            color: get($colors, purple);
          }
        }
      }
    }
    &-link {
      @include transition();

      @media (hover: hover) {
        &:hover {
          color: get($colors, purple);
        }
      }
      &:active {
        color: get($colors, purple);
      }
    }
    &-exit {
      position: absolute;
      z-index: 2;
      width: 100%;
      height: 100px;
      top: 0;
      left: 0;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
      border-radius: 6px;
      padding: 15px 10px;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-start;
      @include transition();
      opacity: 0;
      visibility: hidden;
    }
    &-btn {
      @extend %button--gray;
      padding: 6px 23px;
    }
  }
}
.footer {
  width: 100%;
  padding-top: 1em;
  padding-bottom: 80px;
  background-color: #fff;

  @include mediaToUp($lg) {
    padding-top: 30px;
    padding-bottom: 40px;
  }
  &__logo {
    flex-shrink: 0;
    @include flexCenter();
    justify-content: flex-start;

    img {
      width: auto;
      height: 30px;
    }
    @include mediaToUp($lg) {
      margin-right: 50px;

      img {
        width: 120px;
        height: auto;
      }
    }
  }
  &__list {
    width: 100%;

    @include mediaToUp($lg) {
      column-count: 2;
    }
    @include mediaToUp($sl) {
      column-count: 3;
    }
    &-item {
      display: flex;
      @include fullSize();
      @include setFontSize(get($font-sizes, small));
      line-height: 2;
      @include transition();

      @media (hover: hover) {
        &:hover {
          color: get($colors, purple);
        }
      }
      &:active {
        color: get($colors, purple);
      }
    }
  }
  &__contacts {
    display: flex;
    flex-direction: column;

    &-item {
      @include setFontSize(get($font-sizes, small));
      line-height: 2;
      @include transition();

      @media (hover: hover) {
        &:hover {
          color: get($colors, purple);
        }
      }
      &:active {
        color: get($colors, purple);
      }
    }
  }
  &__link-group {
    display: flex;

    @include mediaToUp($lg) {
      justify-content: flex-end;
    }
  }
  &__link {
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }
}
.breadcrumbs {
  @include flexCenter();
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 5px;

  &__item {
    @include flexCenter();
    @include setFontSize(12);
    color: rgba(get($colors, black), 0.5);
    line-height: 1.2;
    margin-bottom: 5px;

    &-separator {
      flex-shrink: 0;
      @include flexCenter();
      margin: 0 4px;
    }
    &-link {
      transition: .3s;
      text-decoration: none;

      @media (hover: hover) {
        &:hover {
          cursor: pointer;
          color: get($colors, black);
        }
      }
      &:active {
        color: get($colors, black);
      }
    }
    &-current {
      color: rgba(#5A5A5A, 0.5);
    }
  }
}
.menu {
  width: 100%;
  display: flex;

  &__item {
    flex-grow: 1;

    &:first-child, &:last-child {
      flex-grow: 0.5;
    }
    &:first-child {
      .menu {
        &__link {
          justify-content: flex-start;
        }
      }
    }
    &:last-child {
      .menu {
        &__link {
          justify-content: flex-end;
        }
      }
    }
    &.active {
      .menu {
        &__link {
          color: get($colors, purple);
        }
      }
    }
  }
  &__link {
    @include flexCenter();
    @include fullSize();
    @include transition();
    @include setFontSize(get($font-sizes, small));
    color: rgba(get($colors, black), 0.7);
    font-family: $sans-semibold;

    &-text {
    }
    &-count {
      position: relative;
      top: -50%;
      color: get($colors, purple);
      @include setFontSize(11);
      font-family: $sans-bold;
    }
    @media (hover: hover) {
      &:hover:not(.active) {
        cursor: pointer;
        color: get($colors, purple);
      }
    }
    &:active {
      color: get($colors, purple);
    }
    @include mediaToUp($vl) {
      @include setFontSize(get($font-sizes, default));
    }
  }
  &--main {
    .menu {
      &__link {
        padding: 0 20px;
      }
      &__item {
        &:first-child {
          .menu {
            &__link {
              padding-left: 0;
            }
          }
        }
        &:last-child {
          .menu {
            &__link {
              padding-right: 0;
            }
          }
        }
      }
    }
  }
  &--detail {
    .menu {
      &__item {
        flex-grow: 0;
        padding: 0 30px;

        &:first-child {
          padding-left: 0;
        }
        &.active {
          .menu {
            &__link {
              color: get($colors, black);
            }
          }
        }
      }
      &__link {
        @include setFontSize(get($font-sizes, small));
        padding: 12px 0;

        @media (hover: hover) {
          &:hover:not(.active) {
            cursor: pointer;
            color: get($colors, black);
          }
        }
        &:active {
          color: get($colors, black);
        }
      }
    }
  }
  &Button {
    @include flexCenter();
    width: 20px;
    height: 30px;

    @include mediaToUp($lg) {
      width: 30px;
    }
    @media (hover: hover) {
      &:hover {
        cursor: pointer;

        .menuButton {
          &__burger {
            &:before {
              top: -8px;
            }
            &:after {
              top: 8px;
            }
          }
        }
      }
    }
    .menu-is-open & {
      .menuButton {
        &__burger {
          background-color: transparent;

          &:before, &:after {
            top: 0;
          }
          &:before {
            transform: rotate(45deg);
          }
          &:after {
            transform: rotate(-45deg);
          }
        }
      }
    }
    &__burger {
      position: relative;
      height: 2px;
      width: 100%;
      background-color: get($colors, purple);
      transition: .2s;

      &:before, &:after {
        content: '';
        position: absolute;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: get($colors, purple);
        transition: .3s;
      }
      &:before {
        top: -6px;
      }
      &:after {
        top: 6px;
      }
      @include mediaToUp($lg) {
        width: 30px;

        &:before {
          top: -10px;
        }
        &:after {
          top: 10px;
        }
      }
    }
  }
  &Mobile {
    @include fullSizePos();
    position: fixed;
    z-index: 120;
    background-color: #fff;
    padding: 50px 0;
    transition: opacity .4s, visibility .4s, transform .4s;
    opacity: 0;
    visibility: hidden;
    display: flex;
    flex-direction: column;
    transform: translateY(-10%);
    overflow: auto;

    .menu-is-open & {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }
    @include mediaToUp($lg) {
      padding-top: 60px;
    }
    &__nav {
      width: 100%;
      margin-bottom: 50px;

      &-item {
        border-bottom: 1px solid #F1F1F1;

        &.active {
          .menuMobile__nav {
            &-link {
              color: get($colors, purple);
            }
          }
        }
        &.open {
          .menuMobile__nav-title {
            .text {
              color: get($colors, purple);
            }
            .icon {
              transform: rotate(180deg);
            }
          }
        }
      }
      &-link, &-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1em 0;
      }
      &-title {
        .text {
          @include transition();
        }
        .icon {
          @include flexCenter();
          @include transition();
          margin-left: 10px;

          svg {
            fill: get($colors, purple);
            width: 10px;
            height: 10px;
          }
        }
      }
      &-sub {
        height: 0;
        overflow: hidden;
        position: relative;

        &:before {
          content: '';
          position: absolute;
          width: 100%;
          height: 1px;
          left: 0;
          top: 0;
          background-color: #F1F1F1;
        }
        &-item {
          background-color: rgba(#F1F1F1, 0.3);
          border-bottom: 1px solid #F1F1F1;

          &.active {
            .menuMobile__nav-sub {
              &-link {
                color: get($colors, purple);
              }
            }
          }
          &:last-child {
            border-bottom: none;
          }
        }
        &-link {
          display: block;
          padding: 1em 0;
          @include transition();

          &:active {
            background-color: get($colors, lilac);
          }
        }
      }
    }
    &__footer {
      margin-top: auto;
    }
    &__contacts {
      &-item {
        @include setFontSize(get($font-sizes, small));
        display: block;
        margin-bottom: 0.6em;
        color: rgba(get($colors, black), 0.5);
      }
    }
    &__link-group {
      display: flex;
      margin-top: 20px;
    }
    &__link {
      margin-right: 20px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
.tabBar {
  position: fixed;
  z-index: 100;
  width: 100%;
  bottom: 0;
  left: 0;
  height: 60px;
  transition: transform .3s;

  .menu-is-open & {
    transform: translateY(100%);
  }
  &.open {
    .tabBar__menu {
      border-radius: 0;
      box-shadow: none;
    }
  }
  &__menu {
    position: relative;
    @include fullSize();
    z-index: 2;
    background-color: #fff;
    border-radius: 6px 6px 0 0;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.25);
    padding: 0 20px;
    display: flex;

    &-item {
      flex-grow: 1;
      width: 25%;

      &.open {
        .tabBar__menu {
          &-btn {
            .icon {
              &:before {
                opacity: 1;
              }
            }
          }
          &-sub {
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }
    &-link, &-btn {
      @include fullSize();
      @include flexCenterCol();

      .text {
        color: get($colors, black);
        font-family: $sans-semibold;
        @include setFontSize(10);
        line-height: 1;
        white-space: nowrap;
      }
      .icon {
        position: relative;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        @include flexCenter();
        margin-bottom: 2px;

        &:before {
          content: '';
          position: absolute;
          z-index: 2;
          top: 50%;
          left: 50%;
          width: 31px;
          height: 31px;
          border-radius: 50%;
          background-color: get($colors, lilac);
          pointer-events: none;
          opacity: 0;
          transform: translate3d(-50%,-50%,0);
          @include transition();
        }
        &--double {
          width: auto;

          svg {
            margin-right: 5px;

            &:last-child {
              margin-right: 0;
            }
          }
        }
        svg {
          position: relative;
          z-index: 3;
          fill: get($colors, purple);
          width: 20px;
          height: 20px;

          &.icon-zoom, &.icon-add {
            fill: none;
          }
          &.icon-zoom {
            height: 18px;
          }
          &.icon-user {
            height: 19px;
          }
          &.icon-truck {
            width: 26px;
            height: 26px;
          }
          &.icon-box {

          }
        }
      }
    }
    &-link {
      &:active {
        .icon {
          background-color: get($colors, lilac);
        }
      }
    }
  }
  &__menu-sub {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 50px;
    bottom: 100%;
    left: 0;
    border-radius: 6px 6px 0 0;
    box-shadow: 0 -10px 15px rgba(0, 0, 0, 0.05), inset 0 0 0 1px get($colors, purple);
    display: flex;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    @include transition();
    background-color: #fff;

    &-item {
      width: 50%;
      @include flexCenter();
      text-align: center;
      border-right: 1px solid get($colors, purple);
      @include setFontSize(12);
      font-family: $sans-semibold;
      line-height: 1;
      @include transition();

      &:last-child {
        border-right: none;
      }
      &:active {
        color: get($colors, purple);
      }
    }
  }
}
.photo-loader {
  $photo-loader-color: #98989C;

  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;

  &--circle {
    top: -10px;
    width: 60px;
    height: 60px;
    background-color: #fff;
    border-radius: 50%;

    .photo-loader {
      &__title {
        @include setFontSize(10);
        text-align: center;
        text-decoration: none;
        line-height: 1.3;
      }
      &__img {
        padding: 8px;

        img {
          max-width: 100%;
          max-height: 100%;
        }
      }
    }
    @include mediaToUp($md) {
      width: 80px;
      height: 80px;

      .photo-loader__img {
        padding: 10px;
      }
    }
  }
  &__input {
    @include fullSizePos();
    z-index: 2;
    opacity: 0;

    @media (hover: hover) {
      &:hover {
        cursor: pointer;

        & + .photo-loader__title {
          color: get($colors, purple);
          text-decoration-color: transparent;
        }
      }
    }
  }
  &__title {
    @include setFontSize(13);
    @include transition();
    text-decoration: underline;
    line-height: 1.5;
    color: $photo-loader-color;

    @include mediaToUp($lg) {
      @include setFontSize(get($font-sizes, small));
    }
  }
  &__icon {
    @include flexCenter();
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 15px;
    border: 1px solid #98989C;
    display: none;

    svg {
      width: 16px;
      height: 16px;
      fill: rgba(get($colors, black), 0.4);
    }
    @include mediaToUp($lg) {
      width: 60px;
      height: 60px;

      svg {
        width: 18px;
        height: 18px;
      }
    }
    @include mediaToUp($xl) {
      display: flex;
    }
  }
  &__img {
    @include fullSize();
    @include flexCenter();

    img {
      max-width: 100%;
      max-height: 70px;
    }
  }
}
.form {
  width: 100%;
  user-select: none;
  @include formNormalize();

  &__item {
    width: 100%;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
  &__field {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;

    &-item {
      position: relative;
      flex-grow: 1;
    }
    &--multiple {
      .form {
        &__field-item {
          max-width: 50%;

          &:first-child {
            .form__label {
              border-radius: 6px 0 0 6px;
              border-left: 1px solid #CFCFCF;
            }
          }
          &:last-child {
            .form__label {
              border-radius: 0 6px 6px 0;
              border-right: 1px solid #CFCFCF;

              &:after {
                display: none;
              }
            }
          }
        }
        &__label {
          position: relative;
          border-right: none;
          border-left: none;
          border-radius: 0;

          &:after {
            content: '';
            position: absolute;
            height: 80%;
            top: 10%;
            right: 0;
            width: 1px;
            background-color: #CFCFCF;
          }
        }
      }
    }
  }
  &__label {
    width: 100%;
    display: flex;
    align-items: center;
    height: 50px;
    border-radius: 6px;
    border: 1px solid #CFCFCF;
    background-color: #fff;
    margin: 0;

    &--select {
      border: none;
    }
    &--radio, &--checkbox, &--code {
      border: none;
      padding: 0;
      height: auto;
      background: none;
      border-radius: 0;

      @media (hover: hover) {
        &:hover {
          cursor: pointer;
        }
      }
    }
    &--textarea {
      border: none;
      background: none;
      height: auto;
      border-radius: 0;
    }
    &--code {
      height: 200px;

      @include mediaToDown($md) {
        height: 150px;
      }
      .form {
        &__input {
          @include setFontSize(64);
          font-family: $rubik-reg;
          text-align: center;

          @include placeholder {
            color: get($colors, black);
          }
          &:focus {
            @include placeholder {
              color: get($colors, black);
            }
          }
        }
      }
    }
    &--radio {
      .form {
        &__radio {
          &:checked + .form__radio-icon {
            box-shadow: inset 0 0 0 5px get($colors, purple);
          }
        }
      }
      @media (hover: hover) {
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
  &__input {
    @include fullSize();
    border: none;
    outline: none;
    -moz-appearance: none;
    background: none;
    margin: 0;
    padding: 0 10px;
    @include setFontSize(get($font-sizes, small));
    @include transition();
    @include placeholder {
      transition: .2s;
      color: rgba(get($colors, black), 0.4);
    }
    &:focus {
      @include placeholder {
        color: transparent;
      }
    }
    &[readonly] {
    }
    &-icon {
      flex-shrink: 0;
      @include flexCenter();
      padding-right: 10px;

      svg {
        width: 16px;
        height: 16px;
      }
    }
    &--code {

    }

    @include mediaToUp($lg) {
      padding: 0 20px;
    }
  }
  &__textarea {
    @extend .form__input;
    padding-top: 10px;
    height: 100px;
    resize: none;
    background-color: #fff;
    border: 1px solid #CFCFCF;
    border-radius: 6px;
  }
  &__title {
    @include setFontSize(get($font-sizes, middle));
    margin-bottom: 10px;
    font-family: $rubik-reg;

    @include mediaToUp($lg) {
      @include setFontSize(get($font-sizes, middle-up));
      margin-bottom: 15px;
    }
  }
  &__subtitle {
    margin-bottom: 20px;
  }
  &__caption-wr {
    display: flex;
  }
  &__caption {
    flex-grow: 1;
    display: block;
    margin-bottom: 5px;
    @include setFontSize(get($font-sizes, small));

    @include mediaToUp($lg) {
      @include setFontSize(get($font-sizes, default));
      margin-bottom: 10px;
    }
  }
  &__info {
    color: rgba(get($colors, black), 0.4);
    @include setFontSize(11);

    a {
      text-decoration: underline;
      @include transition();

      @media (hover: hover) {
        &:hover {
          cursor: pointer;
          text-decoration-color: transparent;
        }
      }
    }
  }
  &__hint {
    margin-top: 0.5em;
    color: rgba(get($colors, black), 0.4);
    @include setFontSize(get($font-sizes, small));
  }
  &__link {
    line-height: 1.5;

    a {
      color: get($colors, purple);
      text-decoration: underline;
      @include transition();

      @media (hover: hover) {
        &:hover {
          cursor: pointer;
          text-decoration-color: transparent;
        }
      }
    }
  }
  &__select {
    width: 100%;
    height: 100%;
    color: get($colors, black);

    .ss-single-selected, .ss-multi-selected {
      height: 100%;
      border: 1px solid #CFCFCF;
      border-radius: 6px;
      box-shadow: none;
      padding: 0 10px;
      @include setFontSize(get($font-sizes, small));

      .ss-arrow span {
        border-color: get($colors, purple);
      }
      .ss-add {
        position: absolute;
        height: 100%;
        top: 0;
        right: 0;
        width: 34px;
        margin: 0;
        flex: 0;
        @include flexCenter();

        .ss-plus {
          background-color: get($colors, purple);

          &:after {
            background-color: get($colors, purple);
          }
        }
      }

      @include mediaToUp($lg) {
        padding: 0 10px 0 20px;
      }
    }
    .ss-multi-selected {
      align-items: center;
      padding-right: 34px;

      .ss-values {
        width: 100%;
        overflow: hidden;
        flex-wrap: nowrap;

        .ss-disabled {
          display: inline;
          margin: 0;
          padding: 0;
          color: rgba(get($colors, black), 0.4);
        }
        .ss-value {
          padding: 6px 8px;
          margin-top: 0;
          margin-bottom: 0;
          @include setFontSize(get($font-sizes, small));
          color: get($colors, black);
          background-color: rgba(get($colors, purple), 0.1);
          white-space: nowrap;

          &-delete {
            @include setFontSize(14);
            font-family: $sans-semibold;
            color: get($colors, orange);
          }
        }
      }
    }
    .ss-content {
      border-top-color: get($colors, purple);

      .ss-list {
        .ss-option {
          @include transition();
          @include setFontSize(get($font-sizes, small));
          padding-top: 14px;
          padding-bottom: 14px;

          &:hover:not(.ss-disabled) {
            color: get($colors, black);
            background-color: rgba(#CFCFCF, 0.3);
          }
          &.ss-disabled {
            cursor: default;
            color: get($colors, black);
            background-color: rgba(get($colors, purple), 0.07);
          }
        }
      }
    }
  }
  &__button {
    @extend %button--gray;
  }
  &__checkbox, &__radio {
    position: absolute;
    opacity: 0;
    visibility: hidden;
  }
  &__checkbox {
    &:checked + .form__checkbox-icon svg {
      opacity: 1;
    }
    &-icon {
      @include flexCenter();
      width: 20px;
      height: 20px;
      background-color: rgba(get($colors, purple), 0.1);
      border-radius: 3px;

      svg {
        fill: get($colors, purple);
        width: 14px;
        height: 14px;
        @include transition();
        opacity: 0;
      }
    }
    &-caption {
      margin-left: 10px;
      user-select: none;
      @include setFontSize(get($font-sizes, small));
      @include transition();
    }
  }
  &__radio {
    &-icon {
      flex-shrink: 0;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      margin-right: 10px;
      box-shadow: inset 0 0 0 2px get($colors, purple);
      @include transition();
    }
    &-caption {

    }
  }
  &__button {
    @extend %button--gray;
    @extend %button--form;
  }
  .timer-start {
    a {
      @include transition();
      pointer-events: none !important;
      opacity: 0.3 !important;
    }
  }
  &__switch {
    &-button {
      display: flex;
      align-items: center;
      pointer-events: none;

      &.switch-on {
        .form__switch-button {
          &-icon {
            &:after {
              transform: translate3d(26px,-50%,0);
            }
          }
        }
      }
      @media (hover: hover) {
        &:hover {
          cursor: pointer;
        }
      }
      &-option {
        @include transition();
        @include setFontSize(12);
        color: get($colors, black);

        &.active {
          color: get($colors, purple);
        }
        @include mediaToUp($sm) {
          @include setFontSize(get($font-sizes, small));
        }
        @include mediaToUp($lg) {
          @include setFontSize(get($font-sizes, default));
        }
      }
      &-icon {
        pointer-events: auto;
        display: block;
        position: relative;
        width: 52px;
        height: 26px;
        border-radius: 26px;
        border: 1px solid rgba(#CACAD3, 0.5);
        margin: 0 10px;

        &:after {
          content: '';
          position: absolute;
          flex-shrink: 0;
          display: block;
          top: 50%;
          width: 22px;
          height: 22px;
          left: 0;
          border-radius: 50%;
          background-color: get($colors, purple);
          box-shadow: 0 4px 4px rgba(59, 42, 219, 0.2);
          @include transition();
          transform: translate3d(2px,-50%,0);
        }
      }
    }
    &-content {
      width: 100%;
      margin-top: 20px;

      &-item {
        width: 100%;
        display: none;

        &.active {
          display: block;
        }
      }
    }
  }
  &--search {
    .form {
      &__input {
        padding: 0 10px;
      }
      &__select {
        .ss-single-selected {
          padding: 0 10px;
        }
      }
    }
  }
  &--create {
    .form {
      &__caption {
        @include setFontSize(get($font-sizes, small));
        margin-bottom: 5px;
      }
      &__label {
        height: 50px;

        &--switch {
          height: 26px;
        }
      }
      &__input {
        @include setFontSize(get($font-sizes, small));

        @include mediaToUp($lg) {
          @include setFontSize(get($font-sizes, default));
        }
      }
      &__select {
        .ss-single-selected {
          @include setFontSize(get($font-sizes, small));

          @include mediaToUp($lg) {
            @include setFontSize(get($font-sizes, default));
          }
        }
      }
      &__button {
        padding: 16px 30px;
      }
      &__item {
        margin-bottom: 30px;

        &:last-child {
          margin-bottom: 0;
        }
        &--transport {
        }
        &--cargo {
          .form {
            &__field {
              margin-bottom: 20px;

              .row {
                width: 100%;
              }
              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }
        &--route {
          .form {
            &__label {
              position: relative;
            }
          }
        }
        &--date {
          .form {
            &__caption {
              flex-grow: 0;
              margin-bottom: 0;
              @include setFontSize(get($font-sizes, small));

              @include mediaToUp($lg) {
                @include setFontSize(get($font-sizes, default));
              }
            }
            &__label {
              margin: 0 15px;

              &--date {
                width: auto;
                max-width: 300px;
                flex-grow: 1;
                margin-right: 0;

                @include mediaToUp($sm) {
                  width: 300px;
                }
              }
              &--select {
                width: 90px;
              }
            }
            &__field {
              &-item {
                flex-grow: 0;
                display: flex;
                align-items: center;
                justify-content: flex-start;
              }
            }
            &__select {
            }
          }
        }
        &--payment {
          .form {
            &__caption {
              flex-grow: 0;
              margin-bottom: 0;
              margin-left: 10px;
              @include setFontSize(get($font-sizes, small));

              @include mediaToUp($lg) {
                @include setFontSize(get($font-sizes, default));
              }
            }
            &__label {
              &--price {
                width: auto;
                max-width: 300px;
                flex-grow: 1;
                margin-right: 0;

                @include mediaToUp($sm) {
                  width: 300px;
                }
              }
              &--checkbox {
                width: auto;
                margin-right: 20px;

                &:last-child {
                  margin-right: 0;
                }
              }
            }
            &__field {
              margin-bottom: 20px;

              &:last-child {
                margin-bottom: 0;
              }
              &-item {
                flex-grow: 0;
                margin-right: 20px;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: flex-start;

                &:last-child {
                  margin-right: 0;
                }
              }
            }
          }
        }
        &--term {
          .form {
            &__caption {
              margin-bottom: 0;
              margin-left: 10px;
              @include setFontSize(get($font-sizes, small));

              @include mediaToUp($lg) {
                @include setFontSize(get($font-sizes, default));
              }
            }
            &__radio {
              &:checked + .form__radio-icon {
                background-color: rgba(get($colors, purple), 0.1);

                &:after {
                  opacity: 1;
                }
              }
              &-icon {
                border-radius: 50%;
                position: relative;

                &:after {
                  content: '';
                  position: absolute;
                  width: 60%;
                  height: 60%;
                  top: 20%;
                  left: 20%;
                  border-radius: 50%;
                  background-color: get($colors, purple);
                  @include transition();
                  opacity: 0;
                }
              }
            }
            &__label {
              &--term {
                width: 144px;
              }
              &--radio {
                margin-right: 20px;

                &:last-child {
                  margin-right: 0;
                }
              }
            }
            &__field {
              margin-bottom: 20px;

              &:last-child {
                margin-bottom: 0;
              }
              &-item {
                flex-grow: 0;
                margin-right: 20px;
                display: flex;
                align-items: center;

                &:last-child {
                  margin-right: 0;
                }
              }
            }
          }
        }
        &--comment {}
        &--operator {
          .form {
            &__caption {
              margin-bottom: 10px;
              margin-right: 0;

              @include mediaToUp($md) {
                margin-bottom: 0;
                margin-right: 10px;
              }
            }
            &__field {
              justify-content: space-between;

              &-item {
                flex-grow: 0;
                display: flex;
                flex-direction: column;

                @include mediaToUp($md) {
                  flex-direction: row;
                  align-items: center;
                }
              }
            }
            &__select {
              width: 100%;

              .ss-single-selected {
                @include setFontSize(get($font-sizes, small));
                padding: 0 10px;
              }
              @include mediaToUp($md) {
                width: 206px;
              }
            }
          }
        }
      }
    }
  }
  &--profile {
    .form {
      &__tab {
        display: none;

        &.active {
          display: block;
        }
      }
      &__slide-content {
        height: 0;
        visibility: hidden;

        &.open {
         .form__item {
           opacity: 1;
           @include transition();
         }
        }
        .form__item {
          opacity: 0;

          &:last-child {
            padding-bottom: 30px;
          }
        }
      }
      &__field {
        position: relative;

        &--multiple {
          .form {
            &__input {
              padding: 0 5px;
              text-align: center;
            }
            &__field-item {
              &:first-child {
                .form__label {
                  border-left: none;
                }
              }
              &:last-child {
                .form__label {
                  border-right: none;
                }
              }
            }
          }
        }
      }
      &__label, &__textarea {
        border: none;
      }
      &__select {
        .ss-single-selected, .ss-multi-selected {
          border: none;
        }
      }
      &__caption {
        @include setFontSize(get($font-sizes, small));
        color: rgba(get($colors, black), 0.5);
        margin-bottom: 5px;
      }
      &__link {
        color: rgba(get($colors, black), 0.5);
        text-decoration: underline;
        @include setFontSize(get($font-sizes, small));
        @include transition();

        @media (hover: hover) {
          &:hover {
            text-decoration-color: transparent;
          }
        }
        &:active {
          text-decoration-color: transparent;
        }
      }
      &__add-button {
        width: 100%;

        .add-button {
          padding-left: 0;
          padding-right: 0;
        }
      }
      &__loader {
        width: 100%;
        @include transition();
        @include flexCenterCol();
        border: 1px dashed rgba(#575757, 0.2);
        border-radius: 6px;
        padding: 25px;

        @media (hover: hover) {
          &:hover {
            cursor: pointer;

            .form__loader {
              &-text {
                color: get($colors, purple);
                text-decoration-color: transparent;
              }
            }
          }
        }
        &-input {
          @include fullSizePos();
          z-index: 2;
          opacity: 0;
          cursor: pointer;
        }
        &-icon {
          @include flexCenter();
          margin-bottom: 10px;

          svg {
            @include transition();
            width: 22px;
            height: 22px;
          }
        }
        &-text {
          @include transition();
          @include setFontSize(get($font-sizes, small));
          line-height: 1;
          text-decoration: underline;
        }
      }
      &__docs {
        display: flex;
        align-items: flex-start;

        &-list {
          display: flex;
          align-items: flex-start;

          &-item {
            @include flexCenterCol();
            margin-right: 30px;

            &:last-child {
              margin-right: 0;
            }
          }
          &-icon {
            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);

            svg {
              width: 40px;
              height: 50px;
            }
          }
          &-caption {
            max-width: 70px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-top: 10px;
            color: rgba(get($colors, black), 0.4);
            @include setFontSize(get($font-sizes, small));
          }
        }
        &-add-button {
          position: relative;
          margin-left: 30px;

          &-input {
            @include fullSizePos();
            z-index: 2;
            opacity: 0;
            cursor: pointer;
          }
          &-icon {
            svg {
              width: 40px;
              height: 50px;
            }
          }
        }
      }
      &__button {
        @include mediaToDown($md) {
          width: 100%;
        }
      }
    }
  }
  &--simple {
    .form {
      &__field {
        flex-direction: column;
        align-items: flex-start;
      }
      &__button {
        @include mediaToDown($md) {
          width: 100%;
        }
      }
    }
  }
  &--confirm {
    .form {
      &__caption {
        text-align: center;
        line-height: 2;
      }
      &__link {
        text-align: center;
        line-height: 2;
      }
      &__field {
        align-items: center;
      }
    }
  }
  &--reviews {
    .form {

    }
  }
}
.button {
  @extend %button--gray;
  @extend %button--form;
}
.add-button {
  width: 100%;
  padding: 0 20px;
  height: 50px;
  @include transition();
  @include flexCenter();
  border: 1px dashed rgba(#575757, 0.2);
  border-radius: 6px;

  @media (hover: hover) {
    &:hover {
      cursor: pointer;

      .icon {
        background-color: rgba(get($colors, purple), 0.5);

        svg {
          fill: #fff;
        }
      }
    }
  }
  .icon {
    flex-shrink: 0;
    @include transition();
    @include flexCenter();
    margin-right: 20px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 0 6px 15px rgba(91, 96, 136, 0.2);

    svg {
      @include transition();
      width: 14px;
      height: 14px;
      fill: get($colors, purple);

      .open & {
        fill: red;
      }
    }
    .open & {
      background-color: #fff;
      transform: rotate(45deg);
    }
    @include mediaToDown($lg) {
      display: none;
    }
  }
  .text {
    @include transition();
    @include setFontSize(get($font-sizes, default));
    line-height: 1;
  }
}
.modal {
  @include fullSizePos();
  position: fixed;
  z-index: 300;
  display: none;

  &.is-open {
    display: block;

    .modal {
      &__overlay {
        animation: modalFadeIn .3s cubic-bezier(0,0,.2,1) both;
      }
    }
    @keyframes modalFadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  }
  &__overlay {
    @include fullSize();
    background-color: rgba(get($colors, black), 0.5);
    overflow: auto;
  }
  &__inner {
    width: 100%;
    max-width: 1090px;
    padding: 0 15px;
    margin: 1em auto;

    @include mediaToUp($lg) {
      margin: 4em auto;
    }
    @include mediaToUp($sl) {
      margin: 8em auto;
    }
  }
  &__header {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
    justify-content: space-between;
    padding-bottom: 20px;

    @include mediaToUp($lg) {
      flex-direction: row;
    }
  }
  &__title {
    @extend %title--large;
    color: #fff;
    line-height: 1.1;
    text-align: center;

    @include mediaToUp($lg) {
      text-align: left;
    }
  }
  &__close {
    @include flexCenter();
    @include transition();
    align-self: flex-end;

    .icon {
      @include flexCenter();
      @include transition();
      transform: rotate(45deg);
      color: #fff;
      line-height: 0;
      @include setFontSize(35);
    }
    svg {
      fill: #fff;
      width: 16px;
      height: 16px;
    }
    @media ( hover: hover ) {
      &:hover {
        cursor: pointer;

        .icon {
          color: get($colors, purple);
        }
      }
    }
    @include mediaToUp($lg) {
      align-self: center;
    }
  }
  &__content {
    width: 100%;
    background-color: #fff;
    box-shadow: 0 50px 100px rgba(0, 0, 0, 0.03);
    border-radius: 6px;
    padding: 25px 20px 35px 20px;

    & > div {
      margin-top: 0;
    }
    @include mediaToUp($md) {
      padding: 40px 40px 100px 40px;
    }
    .form--profile {
      .form {
        &__label {
          &--text, &--email {
            background-color: #F3F3F9;
          }
          &--select {
            .ss-main .ss-single-selected {
              background-color: #F3F3F9;
            }
          }
        }
      }
    }
  }
  &--success {
    .modal {
      &__overlay {
        @include flexCenter();
      }
      &__inner {
        width: 463px;
        margin: 0;
      }
      &__title {
        text-align: center;
        color: get($colors, black);
      }
      &__text {
        margin-top: 10px;
        text-align: center;
      }
      &__icon {
        flex-shrink: 0;
        @include flexCenter();
        margin-bottom: 10px;
        width: 60px;
        height: 60px;
        border: 2px solid rgba(get($colors, purple), 0.1);
        border-radius: 50%;

        &-circle {
          width: 34px;
          height: 34px;
          @include flexCenter();
          border-radius: 50%;
          background-color: get($colors, purple);
          box-shadow: 0 6px 10px rgba(75, 93, 255, 0.25);

          svg {
            width: 14px;
            height: 14px;
            fill: #fff;
          }
        }
      }
      &__content {
        position: relative;
        padding-bottom: 50px;
        @include flexCenterCol();
      }
      &__close {
        position: absolute;
        z-index: 2;
        top: 20px;
        right: 20px;
        width: 12px;
        height: 12px;
        @include flexCenter();

        .icon {
          @include fullSize();
          color: get($colors, btn-gray);
        }
        @media ( hover: hover ) {
          &:hover {
            cursor: pointer;

            .icon {
              color: get($colors, purple);
            }
          }
        }
      }
      &__button {
        @extend %button--gray;
        @extend %button--form;
        margin-top: 35px;
      }
    }
  }
}
.notify {
  position: fixed;
  z-index: 150;
  bottom: 0;
  right: 0;
  width: 100%;

  @include mediaToUp($md) {
    width: auto;
    bottom: 10px;
    right: 10px;
  }
}
.alert {
  width: 100%;
  padding: 20px;
  overflow: hidden;
  margin: 2px 0 0 0;
  color: #fff;
  font-family: $sans-light;
  background-color: get($colors, purple);
  border-radius: 0;

  @include mediaToUp($md) {
    width: 400px;
    padding: 20px 30px;
    border-radius: 4px;
    margin: 5px 0;
  }
}
.js-customSelect {
  min-width: 90px;

  .ss-single-selected {
    align-items: center;

    .placeholder {
      display: inline;
      line-height: 1.3;
    }
    .ss-arrow span {
      margin: 0;
    }
  }
  .ss-content {
    .ss-list {
      max-height: 250px;

      .simplebar-track.simplebar-vertical {
        width: 8px;
      }
      .simplebar-scrollbar {
        &:before {
          background-color: rgba(get($colors, purple), 0.2);
          opacity: 1;
        }
      }
    }
  }
}
.datepicker {
  &-input {
    cursor: pointer;
  }
}
.spinner {
  @include fullSizePos();
  @include flexCenter();
  @include transition();
  z-index: 10;
  background-color: #fff;

  img {
    width: 30px;
    height: auto;
  }
  .loaded & {
    opacity: 0;
    visibility: hidden;
  }
}
/* rating - start */
.rating {
  width: 100%;
  &--line {
    .rating {
      &__star {
        padding-right: 4px;
      }
    }
  }
  &--static {
    pointer-events: none;
  }
  &--small {
    .rating {
      &__star {
        padding-right: 2px;

        svg {
          width: 10px;
          height: 10px;
        }
      }
    }
  }
  &__stars {
    display: flex;
    align-items: center;
  }
  &__star {
    flex-shrink: 0;
    @include flexCenter();
    padding-right: 12px;

    &:last-child {
      margin-right: 0;
    }
    &.tmp_fs, &.fullStar {
      svg {
        fill: #FF8710;
      }
    }
    &.tmp_es {
      svg {
        fill: #AEAEAE !important;
      }
    }
    svg {
      @include transition;
      fill: #AEAEAE;
      width: 20px;
      height: 20px;
    }
    @media (hover : hover) {
      cursor: pointer;

      &:hover {
        svg {
          fill: #FF8710;
        }
      }
    }
  }

  &-create {
    &__item {
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 15px;

      &--radio {
        margin-top: 25px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    &__caption {
      flex-shrink: 0;
      width: 50%;
      @include setFontSize(get($font-sizes, default));
      margin-right: 30px;
    }
    &__radio {
      .form {
        &__label {
          width: auto;
          margin-right: 20px;
        }
      }
    }
  }
  &-tooltip {
    position: absolute;
    z-index: 2;
    top: 100%;
    left: 0;
    width: auto;
    min-width: 400px;
    height: auto;
    background-color: #fff;
    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.19);
    border-radius: 6px;
    overflow: hidden;
    margin-top: 4px;
    padding: 1em 2em 2em 2em;
    opacity: 0;
    visibility: hidden;

    &.is-open {
      transition: opacity .3s, visibility .3s;
      opacity: 1;
      visibility: visible;
    }
    &__close {
      position: absolute;
      z-index: 2;
      top: 0;
      right: 0;
      width: 2em;
      height: 2em;
      @include flexCenter();

      svg {
        @include transition();
        fill: get($colors, gray);
        width: 0.8em;
        height: 0.8em;
      }
      @media (hover: hover) {
        &:hover {
          cursor: pointer;

          svg {
            fill: get($colors, purple);
          }
        }
      }
    }
    &__header {
      border-bottom: 1px solid #DCDCDC;
      padding-bottom: 1em;
      margin-bottom: 1em;
    }
    &__link {
      display: block;
      @include setFontSize(12);
      color: get($colors, purple);
      text-decoration: underline;
      @include transition;

      @media (hover:hover) {
        &:hover {
          text-decoration-color: transparent;
        }
      }
      &:active {
        text-decoration-color: transparent;
      }
    }
    &__title {
      @include setFontSize(get($font-sizes, small));
      font-family: $sans-semibold;
      line-height: 1;
      margin-bottom: 10px;
    }
    &__button {
      @extend %button--border;
      border-color: get($colors, black);
      @include setFontSize(get($font-sizes, small));
      white-space: nowrap;
      padding: 10px 19px;
    }
    .rating {
      &__star {
        flex-shrink: 1;
        padding-right: 5px;
      }
    }
  }
  &-detail {
    &__title {
      @include setFontSize(get($font-sizes, small));
      font-family: $sans-semibold;
      line-height: 1;
      margin-bottom: 1em;
    }
    &__list {
      &-item {
        &-caption {
          @include setFontSize(12);
        }
        &-count {
          color: #AEAEAE;
          @include setFontSize(12);
          line-height: 1;
          text-align: center;
        }
      }
    }
  }
  &-company {
    border-bottom: 1px solid #DCDCDC;
    padding-bottom: 1em;
    margin-bottom: 1em;

    a {
      display: block;
      text-decoration: underline;
      @include transition;

      @media (hover: hover) {
        &:hover {
          text-decoration-color: transparent;
        }
      }
      &:active {
        text-decoration-color: transparent;
      }
    }
    &__info {
      margin-top: 0.3em;
      display: flex;
      align-items: center;

      li {
        margin-right: 0.4em;

        &:last-child {
          margin-right: 0;
        }
      }
    }
    &__employee {
      @include setFontSize(get($font-sizes, small));
      line-height: 1.2;
      margin-bottom: 6px;
    }
    &__name {
      font-family: $sans-semibold;
      @include setFontSize(get($font-sizes, small));
    }
    &__city {
      color: get($colors, purple);
      @include setFontSize(12);
      line-height: 1;
    }
    &__id {
      color: rgba(get($colors, black), 0.5);
      @include setFontSize(12);
      line-height: 1;
    }
    &__count {
      color: rgba(get($colors, black), 0.5);
      @include setFontSize(12);
      line-height: 1;
    }
  }
}
/* rating - end */
.pagination {
  display: flex;

  @include mediaToUp($lg) {
  }
  &__item {
    border: 1px solid #CFCFCF;
    border-radius: 6px;
    min-width: 40px;
    height: 40px;
    margin-right: 10px;

    @include mediaToUp($md) {
      min-width: 46px;
      height: 46px;
    }
    &--button {
      .pagination {
        &__link {
          padding: 0 20px;
        }
      }
    }
    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }
    &.active {
      border-color: get($colors, black);
    }
    &:last-child {
      margin-right: 0;
    }
  }
  &__link, &__text {
    @include fullSize();
    @include flexCenter();
    text-align: center;
    padding: 0 10px;
  }
}
.card {
  position: relative;
  background-color: #FFFFFF;
  overflow: hidden;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.19);
  border-radius: 6px;
  @include transition();

  @media (hover: hover) {
    overflow: visible;

    &:hover {
      box-shadow: 0 15px 35px rgba(0, 0, 0, 0.29);
    }
  }
  &.open {
    .card-col {
      display: block;

      &:nth-child(2), &:nth-child(3) {
        width: 100%;
        border-bottom-color: #F1F1F1;
      }
      &:nth-child(n) {
        .card {
          &__caption {
            display: block;
          }
          &__column {
            padding: 15px 40px;

            &--route {
              padding-left: 15px;
            }
          }
        }
      }
    }
  }
  .card-col {
    display: none;
    border-bottom: 1px solid #F1F1F1;

    &:nth-child(1), &:nth-child(2), &:nth-child(3) {
      display: block;
    }
    &:nth-child(1) {
      border-bottom: none;
    }
    &:nth-child(2), &:nth-child(3) {
      width: 50%;
      border-bottom-color: transparent;

      .card__column {
        padding: 15px;
      }
    }
    &:nth-child(3) {
      .card__column {
        padding-left: 0;
      }
    }
    @include mediaToUp($lg) {
      display: block;
      border-bottom: none;

      &:nth-child(2), &:nth-child(3) {
        width: auto;

        .card__column {
          padding: 0;
        }
      }
    }
  }
  &__column {
    padding: 15px 40px;

    &--company {
      background-color: #F8F8F8;
      padding-top: 10px;
    }
    &--route {
      .card {
        &__caption {
          padding-left: 25px;
        }
      }
    }
    @include mediaToUp($lg) {
      padding: 0;

      &--company {
        background: none;
      }
    }
  }
  &__top {
    position: relative;
    width: 100%;
    padding: 0;
    @include setFontSize(get($font-sizes, small));

    @include mediaToUp($lg) {
      padding: 35px 60px 10px 60px;
      border-bottom: 1px solid rgba(#CFCFCF, 0.5);
    }
  }
  &__bottom {
    width: 100%;
    padding: 15px 40px 20px 40px;
    @include setFontSize(13);
    display: none;

    .open & {
      display: block;
    }
    @include mediaToUp($lg) {
      padding: 20px;
      display: block;
    }
  }
  &__checkbox {
    position: absolute;
    z-index: 2;
    top: 10px;
    left: 10px;
    display: block;
    width: 20px;
    height: 20px;
    background-color: rgba(#4B5DFF, 0.1);
    border-radius: 3px;
    overflow: hidden;

    &-icon {
      @include flexCenter();
      @include fullSizePos();
      @include transition();
      z-index: 2;
      background-color: rgba(#4B5DFF, 0.1);
      opacity: 0;

      svg {
        width: 12px;
        height: 12px;
        fill: #fff;
      }
    }
    @media (hover: hover) {
      &:hover {
        cursor: pointer;

        .card__checkbox-icon {
          opacity: 1;
        }
      }
    }
    input[type="checkbox"] {
      position: absolute;
      opacity: 0;
      visibility: hidden;

      &:checked + .card__checkbox-icon {
        opacity: 1;
        background-color: get($colors, purple);
      }
    }
    @include mediaToUp($lg) {
      top: 36px;
      left: 20px;
    }
  }
  &__button-wr {
    position: absolute;
    z-index: 2;
    top: 0;
    right: 10px;
    width: 20px;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 1em;

    @include mediaToUp($md) {
      padding-top: 20px;
    }
    @include mediaToUp($lg) {
      width: 60px;
    }
  }
  &__button {
    width: 16px;
    height: 16px;
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }
    svg {
      width: 100%;
      height: 100%;
      fill: rgba(get($colors, black), 0.5);
    }
    @media (hover: hover) {
      &:hover {
        cursor: pointer;
      }
    }
    &--message {
      width: 20px;
      height: 20px;
      z-index: 2;
      @include flexCenter();

      svg {
        width: 100%;
        height: 100%;
        fill: rgba(get($colors, black), 0.5);

        path {
          @include transition();
        }
      }
      @media (hover: hover) {
        &:hover {
          cursor: pointer;

          svg {
            mask {
              path {
                fill: white;
              }
            }
            path {
              fill-opacity: 1;
              fill: get($colors, purple);
            }
          }
        }
      }
    }
    &--remove {
      width: 12px;
      height: 12px;
      z-index: 2;
      @include flexCenter();

      svg {
        width: 100%;
        height: 100%;
        fill: rgba(get($colors, black), 0.5);
        @include transition();
      }
      @media (hover: hover) {
        &:hover {
          cursor: pointer;

          svg {
            fill: red;
          }
        }
      }
    }
  }
  &__button-more {
    padding: 10px;
    @include flexCenter();
    border-top: 1px solid #EDEDED;

    .icon {
      @include transition();
      @include flexCenter();

      svg {
        fill: get($colors, purple);
        width: 11px;
        height: 11px;
      }
      .open & {
        transform: rotate(180deg);
      }
    }
    .card {
      &__payment-price {
        margin-right: auto;
        @include setFontSize(get($font-sizes, small));

        .open & {
          display: none;
        }
      }
      &__last-update {
        margin-right: 10px;
        margin-left: auto;
        @include setFontSize(10);
        color: rgba(get($colors, black), 0.5);

        .open & {
          display: none;
        }
      }
    }
  }
  &__caption {
    @include setFontSize(get($font-sizes, small));
    color: rgba(get($colors, black), 0.5);
    margin-bottom: 10px;
    display: none;
  }
  &__company {
    &-icon {
      position: absolute;
      z-index: 2;
      top: 40px;
      left: 10px;
      width: 18px;
      height: 18px;
    }
    &-item {
      display: flex;
      align-items: center;
      margin-bottom: 5px;
    }
    &-name {
      display: flex;
      align-items: center;

      .icon {
        flex-shrink: 0;
        @include flexCenter();
        margin-right: 10px;

        svg {
          width: 18px;
          height: 18px;
        }
      }
      .text {
        @include setFontSize(12);
        text-decoration: underline;
        @include transition();

        @media (hover: hover) {
          &:hover {
            text-decoration-color: transparent;
          }
        }
        @include mediaToUp($md) {
          @include setFontSize(get($font-sizes, small));
        }
        @include mediaToUp($lg) {
          font-family: $sans-semibold;
        }
      }
    }
    &-id {
      color: rgba(get($colors, black), 0.5);
      @include setFontSize(12);
    }
    &-rating {
      position: relative;
      margin-left: 7px;

      @media (hover: hover) {
        &:hover {
          cursor: pointer;
        }
      }
    }
    &-factoring {
      display: flex;
      align-items: center;

      .icon {
        flex-shrink: 0;
        @include flexCenter();
        margin-right: 10px;

        svg {
          width: 18px;
          height: 18px;
          fill: get($colors, purple);
        }
      }
      .text {
        color: get($colors, purple);
      }
    }
    &-phone {
      color: get($colors, purple);
      //text-decoration: underline;
      @include transition();
      @include setFontSize(10);

      @media (hover: hover) {
        &:hover {
          color: get($colors, purple);
          //text-decoration: underline;
          text-decoration-color: transparent;
        }
      }
      @include mediaToUp($md) {
        @include setFontSize(get($font-sizes, small));
      }
    }
    &-employee {
      &--short-name {
        margin-right: 10px;
        @include setFontSize(10);

        @include mediaToUp($md) {
          @include setFontSize(get($font-sizes, small));
        }
      }
    }
  }
  &__route {
    position: relative;

    &-date {
      padding-left: 25px;
      color: rgba(get($colors, black), 0.5);
      @include setFontSize(12);

      @include mediaToUp($lg) {
        position: absolute;
        width: 100%;
        bottom: 100%;
        left: 0;
      }
    }
    &-from, &-to {
      display: flex;
      align-items: center;
    }
    &-icon {
      @include flexCenter();
      flex-shrink: 0;
      margin-right: 10px;

      svg {
        width: 16px;
        height: 19px;
      }
    }
    &-point {
      display: inline;
      font-family: $sans-semibold;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &-radius {
      border: 1px solid #AEAEAE;
      border-radius: 4px;
      @include setFontSize(10);
      padding: 2px 6px;
      margin-left: 10px;
      white-space: nowrap;
      display: none;

      .open & {
        display: block;
      }
      @include mediaToUp($lg) {
        display: block;
      }
    }
    &-distance {
      margin: 4px 0;
      display: flex;
      align-items: center;

      &-icon {
        @include flexCenter();
        flex-shrink: 0;
        margin-right: 10px;

        svg {
          width: 16px;
          height: 27px;
        }
      }
      &-value {
        @include setFontSize(10);
      }
    }
    &-point-more {
      margin-top: 0.5em;
      padding-left: 25px;
      color: get($colors, purple);
      text-decoration: underline;
      @include transition();
      @include setFontSize(13);

      @media (hover: hover) {
        &:hover {
          cursor: pointer;
          text-decoration-color: transparent;
        }
      }
    }
  }
  &__cargo {
    &-properties {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      &-item {
        @include flexCenter();
        border: 1px solid #AEAEAE;
        border-radius: 4px;
        @include setFontSize(12);
        padding: 2px 6px;
        margin-right: 8px;
        margin-bottom: 8px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
    &-type {
      margin-bottom: 3px;
      @include setFontSize(12);

      .open & {
        font-family: $sans-semibold;
        @include setFontSize(get($font-sizes, small));
      }
      @include mediaToUp($lg) {
        font-family: $sans-semibold;
        @include setFontSize(get($font-sizes, small));
      }
    }
    &-transport, &-permission {
      margin-bottom: 5px;
      @include setFontSize(12);

      .open & {
        font-family: $sans-semibold;
        @include setFontSize(get($font-sizes, small));
      }
      @include mediaToUp($lg) {
        font-family: $sans-semibold;
        @include setFontSize(get($font-sizes, small));
      }
    }
    &-temp {
      color: get($colors, purple);
      @include setFontSize(13);
    }
  }
  &__transport {
    &-item {
      display: block;
      font-family: $sans-semibold;
      margin-bottom: 5px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &__payment {
    &-price {
      font-family: $sans-bold;
      margin-bottom: 2px;
    }
    &-rate {
      color: rgba(get($colors, black), 0.5);
      @include setFontSize(11);
      margin-bottom: 5px;
    }
    &-delay {
      display: flex;
      flex-direction: column;

      &-title {
        color: rgba(get($colors, black), 0.5);
        @include setFontSize(11);
      }
      &-value {
      }
    }
  }
  &__info {
    color: rgba(get($colors, black), 0.7);
  }
  &__comment {
    width: 100%;
    margin-bottom: 0;

    &-input {
      width: 100%;
      height: 46px;
      background-color: #F9F9F9;
      border-radius: 6px;
      padding: 0 20px;
      @include setFontSize(13);
    }
  }
}
.cards {
  &--search {
    .card {
      &__company {
        &-item {
          &:nth-child(n) {
            padding-left: 0
          }
          @include mediaToUp($lg) {
            &:nth-child(n) {
              padding-left: 28px;
            }
            &:first-child {
              padding-left: 0;
            }
          }
        }
      }
    }
  }
  &--cargos, &--transport {
    .card {
      &__column {
        &--company {
          background: none;
          padding-left: 15px;

          @include mediaToUp($lg) {
            padding-left: 0;
          }
        }
      }
      &__company {
        &-phone {
          @include setFontSize(12);

          @include mediaToUp($md) {
            @include setFontSize(get($font-sizes, small));
          }
        }
        &-employee {
          font-family: $sans-semibold;
        }
      }
    }
  }
  &--transport {
    //.card {
    //  &.open {
    //    .col-transport {
    //      width: 100%;
    //    }
    //  }
    //  .col {
    //    &-transport {
    //      width: 50%;
    //
    //      @include mediaToUp($lg) {
    //        width: auto;
    //      }
    //    }
    //  }
    //  &__column {
    //    &--transport {
    //      display: block;
    //    }
    //  }
    //}
  }
  &__table {
    width: 100%;
    margin-top: 30px;

    @include mediaToUp($lg) {
      margin-top: 40px;
    }
    &-header {
      padding-left: 60px;
      padding-right: 60px;
      padding-bottom: 16px;
    }
    &-title {
      @include setFontSize(13);
      color: rgba(get($colors, black), 0.5);
    }
    &-row {
      width: 100%;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &__nav {
    width: 100%;
    margin-top: 50px;
  }
  &__btn {
    border: 1px solid get($colors, black);
    border-radius: 6px;
    padding: 10px 20px;
    line-height: 1.2;
    @include flexCenter();
    @include transition();

    @media (hover: hover) {
      &:hover {
        cursor: pointer;
        border-color: get($colors, btn-gray);
        background-color: get($colors, btn-gray);
        color: #fff;
      }
    }
    &[disabled] {
      pointer-events: none;
      opacity: 0.3;
    }
    @include mediaToUp($md) {
      padding: 10px 30px;
    }
  }
  &__create {
    width: 100%;
    margin-top: 100px;
    @include flexCenter();
    border: 1px dashed get($colors, btn-gray);
    border-radius: 6px;
    padding: 76px 0;

    &-button {
      @extend %button--gray;
      @extend %button--form;
      padding: 18px 43px;
    }
    @include mediaToDown($lg) {
      margin-top: 50px;
    }
    @include mediaToDown($md) {
      margin-top: 20px;
      padding: 20px;

      &-button {
        width: 100%;
      }
    }
  }
  &__filter {
    display: flex;
    align-items: center;

    &-caption {
      @include setFontSize(13);
      margin-right: 20px;
    }
    &-select {
      width: auto;

      .ss-single-selected {
        height: 40px;
        background-color: transparent;
        border: 1px solid #CFCFCF;
        color: get($colors, black);
        border-radius: 6px;
        padding: 0 10px;

        @include mediaToUp($md) {
          height: 46px;
        }
        .ss-arrow span {
          border-color: get($colors, black);
        }
      }
      .ss-content {
        border-color: #CFCFCF;

        .ss-list {
          .ss-option {
            color: get($colors, black);
            @include transition();
            @include setFontSize(get($font-sizes, small));
            padding: 10px;

            &:hover:not(.ss-disabled) {
              color: get($colors, black);
              background-color: rgba(#CFCFCF, 0.2);
            }
            &.ss-disabled {
              cursor: default;
              color: get($colors, black);
              background-color: rgba(get($colors, purple), 0.1);
            }
          }
        }
      }
    }
  }
  &__pagination {
    @include mediaToUp($lg) {
      margin-left: 50px;
    }
  }
}
/* home */
.home {
  &__search {
    &-button {
      @extend %button--border;
    }
    &-tabs {
      width: 100%;

      &-header {
        &-left {
        }
        &-right {
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }
      }
      &-nav {
        width: 100%;
        display: flex;

        &-item {
          max-width: 50%;
          flex-grow: 1;
          @extend %button--gray;
          @include setFontSize(get($font-sizes, small));
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          margin-right: 2px;
          padding: 1em;

          &:last-child {
            margin-right: 0;
          }
          &.active {
            background-color: #fff;
            color: get($colors, black);
          }
          @include mediaToUp($lg) {
            padding: 20px;
            margin-right: 3px;
            @include setFontSize(get($font-sizes, middle));
          }
        }
      }
      &-content {
        width: 100%;
        background-color: #fff;
        box-shadow: 0 50px 100px rgba(0, 0, 0, 0.03);
        border-radius: 0 0 6px 6px;

        @include mediaToUp($lg) {
          background: none;
          box-shadow: none;
          border-radius: 0;
        }
        &-item {
          width: 100%;
          display: none;

          &.active {
            display: block;
          }
        }
      }
      &-button {
        @extend %button--gray;
        margin-right: 8px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
    &-form {
      width: 100%;

      .form {
        &__label {
          position: relative;
        }
        &__input {
          &[type="number"] {
            text-align: center;
          }
          &.results-show {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
          }
        }
      }
      &-fileds {
        width: 100%;
        padding: 20px 20px 30px 20px;

        @include mediaToUp($lg) {
          padding: 46px 30px 30px 30px;
          background-color: #fff;
          box-shadow: 0 50px 100px rgba(0, 0, 0, 0.03);
          border-radius: 0 6px 0 0;
        }
      }
      &-route-results {
        position: absolute;
        width: 100%;
        height: 250px;
        left: 0;
        top: 100%;
        margin: -1px 0 0 0;
        border: solid 1px #dee2e6;
        border-top-color: get($colors, purple);
        z-index: 10;
        background-color: #fff;
        transform-origin: center top;
        transition: transform .2s, opacity .2s;
        opacity: 0;
        transform: scaleY(0);

        &.results-show {
          opacity: 1;
          transform: scaleY(1);
        }
        .simplebar-track.simplebar-vertical {
          width: 8px;
        }
        .simplebar-scrollbar {
          &:before {
            background-color: rgba(get($colors, purple), 0.2);
            opacity: 1;
          }
        }
        &-list {
          width: 100%;

          &-item, .no_result {
            width: 100%;
            @include transition();
            @include setFontSize(get($font-sizes, small));
            padding: 14px 20px;

            &:hover {
              cursor: pointer;
              background-color: rgba(207, 207, 207, 0.3);
            }
          }
          .no_result {
            pointer-events: none;
          }
        }
      }
      &-add-param {
        width: 100%;
        padding: 0 20px 30px 20px;
        border-radius: 0 0 6px 6px;
        background-color: #fff;
        @include transition();

        &.open {
          background-color: #F9F9F9;
        }
        @include mediaToDown($lg) {
          border-radius: 0;

          &.open {
            & + .home__search-form-footer {
              background-color: #F9F9F9;
            }
          }
        }
        &-btn {
          display: flex;
          align-items: center;

          @media (hover: hover) {
            &:hover {
              cursor: pointer;

              .icon {}
              .text {
                text-decoration-color: transparent;
              }
            }
          }
          .icon {
            @include flexCenter();
            margin-right: 10px;

            svg {
              width: 20px;
              height: 20px;
              fill: get($colors, orange);
            }
          }
          .text {
            text-decoration: underline;
            @include transition();
            @include setFontSize(get($font-sizes, small));
          }
          @include mediaToUp($lg) {
            .text {
              @include setFontSize(get($font-sizes, default));
            }
          }
        }
        &-content {
          height: 0;
          overflow: hidden;

          &.open {
            overflow: visible;
          }
          &-inner {
            @include fullSize();
            padding: 20px 0 30px 0;
          }
        }
        &-title {
          text-align: center;
          margin-bottom: 20px;

          @include mediaToUp($lg) {
            margin-bottom: 30px;
          }
        }
        .form {
          &__label:nth-child(n), &__field-item:nth-child(n) .form__label {
            border: none;
          }
          &__select {
            .ss-single-selected {
              border: none;
            }
            .ss-multi-selected {
              border: none;
            }
          }
        }
      }
      &-footer {
        border-radius: 0 0 6px 6px;
        padding: 0 20px 40px 20px;
        @include transition();

        @include mediaToUp($lg) {
          padding: 30px 0 0 0;
        }
      }
      &-button {
        @extend %button--gray;
      }
    }
    &-results {
      width: 100%;
      margin-top: 40px;

      @include mediaToUp($lg) {
        margin-top: 50px;
      }
      &-title {
        text-align: center;
        margin-bottom: 0.5em;
        @extend %title--large;

        @include mediaToUp($lg) {
          text-align: left;
          margin-bottom: 0.3em;
        }
      }
      &-subtitle {
        @include setFontSize(13);
        color: rgba(get($colors, black), 0.7);
        text-align: center;

        span {
          text-decoration: underline;
          cursor: pointer;
        }
        @include mediaToUp($lg) {
          text-align: left;
        }
      }
    }
  }
}
/*profile */
.profile {
  margin-top: 23px;
  width: 100%;

  @include mediaToUp($xl) {
    background-color: #F3F3F9;
    box-shadow: 0 50px 100px rgba(0, 0, 0, 0.03);
    border-radius: 6px;
    overflow: hidden;
  }
  &--autopark, &--drivers, &--managers, &--payment {
    .profile {
      &__aside-inner {
        @include mediaToDown($xl) {
          padding: 0;
        }
      }
      &__content {
        @include mediaToDown($xl) {
          background: none;
          box-shadow: none;
          border-radius: 0;
          padding: 0;
        }
      }
    }
  }
  &__aside {
    @include fullSize();

    &-inner {
      width: 100%;
      padding: 0 0 1em 0;

      .photo-loader {
        justify-content: center;
      }
    }
    @include mediaToUp($xl) {
      background-color: rgba(#fff, 0.8);
      //box-shadow: 0 50px 100px rgba(0, 0, 0, 0.03);

      &-inner {
        padding: 40px 30px 30px 30px;
      }
    }
  }
  &__sidebar {
    width: 100%;
    padding: 0 0 100px 0;

    &-item {
      width: 100%;
      display: flex;
      align-items: center;
      @include transition();
      padding: 15px 20px;
      border-left: 4px solid transparent;
      @include setFontSize(get($font-sizes, small));

      @media (hover: hover) {
        &:hover {
          background-color: rgba(#F3F3F9, 0.5);
        }
      }
      &:active {
        background-color: #F3F3F9;
      }
      &.active {
        background-color: #F3F3F9;
        border-color: get($colors, purple);
        font-family: $sans-semibold;
      }
      &-icon {
        @include flexCenter();
        margin-right: 20px;

        svg {
          width: 16px;
          height: 16px;
        }
      }
      @include mediaToUp($vl) {
        padding: 25px 30px;
        @include setFontSize(get($font-sizes, default));

        &-icon {
          svg {
            width: 25px;
            height: 25px;
          }
        }
      }
    }
  }
  &__select {
    width: 100%;
    background-color: #fff;
    border-radius: 6px;
    overflow: hidden;

    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1em;
    }
    &-title {
      display: flex;
      align-items: center;

      .icon {
        flex-shrink: 0;
        @include flexCenter();
        margin-right: 10px;

        svg {
          width: 16px;
          height: 16px;
        }
      }
      .text {
        font-family: $sans-semibold;
        @include setFontSize(get($font-sizes, small));
      }
    }
    &-icon {
      flex-shrink: 0;
      @include flexCenter();
      margin-left: 10px;
      @include transition();

      .open & {
        transform: rotate(180deg);
      }
      svg {
        fill: get($colors, black);
        width: 10px;
        height: 10px;
      }
    }
    &-content {
      height: 0;
      overflow: hidden;
    }
    &-list {
      position: relative;
      padding: 0.5em 1em;
      width: 100%;

      &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: #f1f1f1;
        left: 0;
        top: 0;
      }
      &-item {
        display: flex;
        align-items: center;
        padding: 1em 0;

        &.active {
          display: none;
        }
        &-icon {
          flex-shrink: 0;
          @include flexCenter();
          margin-right: 10px;

          svg {
            width: 16px;
            height: 16px;
          }
        }
        &-text {
          @include setFontSize(get($font-sizes, small));
        }
      }
    }
  }
  &__content {
    width: 100%;

    @include mediaToUp($xl) {
      padding: 40px 40px 40px 20px;
    }
    @include mediaToDown($xl) {
      padding: 20px;
      margin-top: 20px;
      background-color: #F3F3F9;
      box-shadow: 0 50px 100px rgba(0, 0, 0, 0.03);
      border-radius: 6px;
      overflow: hidden;
    }
  }
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__add-button {
    @include transition();
    @include flexCenter();

    .icon {
      flex-shrink: 0;
      @include flexCenter();
      margin-left: 10px;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background-color: #fff;
      box-shadow: 0 6px 15px rgba(91, 96, 136, 0.2);

      svg {
        @include transition();
        width: 14px;
        height: 14px;
        fill: get($colors, purple);

        .open & {
          fill: red;
        }
      }
    }
    .text {
      color: rgba(get($colors, black), 0.5);
      @include setFontSize(12);
      line-height: 1;

      @include mediaToUp($md) {
        @include setFontSize(get($font-sizes, small));
      }
    }
  }
  &__title {
    @include setFontSize(get($font-sizes, middle-title));
    margin-bottom: 0;
  }
  &__form {
    margin-top: 20px;

    @include mediaToUp($lg) {
      margin-top: 40px;
    }
  }
  &__photo {
    padding: 50px 40px;
  }
  &__tabs {
    width: 100%;

    &-nav {
      width: 100%;
      display: flex;

      &-item {
        @include flexCenter();
        @include transition();
        max-width: 50%;
        flex-grow: 1;
        padding: 0;
        height: 50px;
        text-align: center;
        background-color: #fff;
        user-select: none;
        outline: none;
        border-right: 1px solid get($colors, lilac);

        &:first-child {
          border-radius: 6px 0 0 6px;
        }
        &:last-child {
          border-radius: 0 6px 6px 0;
          border-right: none;
        }
        @media (hover: hover) {
          &:hover {
            cursor: pointer;
            background-color: rgba(get($colors, purple), 0.1);
          }
        }
        &.active {
          border-color: transparent;
          background-color: get($colors, purple);
          color: #fff;
        }
        @include mediaToUp($lg) {
          padding: 0 45px;
          flex-grow: 0;
        }
      }
    }
    &-content {
      width: 100%;
      margin-top: 30px;

      &-item {
        width: 100%;
        opacity: 0;
        display: none;

        &.active {
          display: block;
          animation: tabFadeIn .4s ease-in both;
        }
      }
      @keyframes tabFadeIn {
        to {
          opacity: 1;
        }
      }
    }
  }
  &__table {
    width: 100%;

    &-header {
      width: 100%;
      padding-bottom: 10px;
      padding-right: 40px;
    }
    &-body {
      width: 100%;
    }
    &-row {
      position: relative;
      padding-right: 40px;
      background-color: #FFFFFF;
      border-radius: 6px;
      margin-bottom: 10px;
      overflow: hidden;
      @include transition();

      @media (hover: hover) {
        &:hover {
          box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
      &-header {
        @include transition();
        padding-bottom: 20px;
        border-bottom: 1px solid transparent;

        .open & {
          margin-bottom: 20px;
          border-color: #EDEDED;
        }
        &-info {
          &-item {
            margin-bottom: 0.5em;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
        .profile__table {
          &-img {
            position: static;
            width: 40px;
            height: 40px;
            border-radius: 2px;
            overflow: hidden;
          }
        }
      }
      &-content {
        @include mediaToDown($lg) {
          height: 0;
          overflow: hidden;
        }
      }
      @include mediaToDown($lg) {
        padding: 20px 20px 0 20px;
      }
    }
    &-cell {
      position: relative;
      @include fullSize();
      display: flex;
      flex-direction: column;
      justify-content: center;

      @include mediaToUp($lg) {
        min-height: 90px;
        padding: 14px 0;
      }
    }
    &-title {
      color: rgba(get($colors, black), 0.5);
      @include setFontSize(10);

      @include mediaToUp($vl) {
        @include setFontSize(get($font-sizes, small));
      }
    }
    &-caption {
      color: rgba(get($colors, black), 0.5);
      @include setFontSize(get($font-sizes, small));
      margin-bottom: 5px;
    }
    &-text {
      @include setFontSize(get($font-sizes, small));

      @include mediaToUp($vl) {
        @include setFontSize(get($font-sizes, default));
      }
    }
    &-link {
      @include transition();

      @media (hover: hover) {
        &:hover {
          color: get($colors, purple);
        }
      }
      &:active {
        color: get($colors, purple);
      }
    }
    &-img {
      @include fullSizePos();
      @include bgCover();
    }
    &-btn-wr {
      position: absolute;
      z-index: 2;
      top: 20px;
      right: 20px;
      width: auto;
      height: auto;
      display: flex;
      align-items: center;

      @include mediaToUp($lg) {
        top: 0;
        right: 0;
        width: 40px;
        padding: 10px 0;
        height: 100%;
        flex-direction: column;
        justify-content: space-between;
      }
    }
    &-btn {
      flex-shrink: 0;
      margin-right: 1em;
      @include flexCenter();

      svg {
        width: 14px;
        height: 14px;
        @include transition();
        fill: rgba(#5A5A5A, 0.5);
      }
      &:last-child {
        margin-right: 0;
      }
      @media (hover: hover) {
        &:hover {
          cursor: pointer;
        }
      }
      &--remove {
        @media (hover: hover) {
          &:hover {
            svg {
              fill: get($colors, orange);
            }
          }
        }
        svg {
          width: 12px;
          height: 12px;
        }
      }
      &--edit, &--copy {
        @media (hover: hover) {
          &:hover {
            svg {
              fill: get($colors, btn-gray);
            }
          }
        }
        svg {
          fill: get($colors, purple);
        }
      }
      @include mediaToUp($lg) {
        margin-right: 0;
      }
    }
    &-btn-more {
      width: 100%;
      border-top: 1px solid #EDEDED;
      @include flexCenter();

      .icon {
        margin: 6px 0;
        @include flexCenter();
        @include transition();

        svg {
          fill: get($colors, purple);
          width: 12px;
          height: 12px;
        }
        .open & {
          transform: rotate(180deg);
        }
      }
    }
  }
  /* user page */
  &-user {
    width: 100%;

    &__photo {
      display: flex;
      justify-content: flex-start;
    }
    &__form {
      margin-top: 20px;

      @include mediaToUp($lg) {
        margin-top: 35px;
      }
    }
  }
  /* company page */
  &-company {
    width: 100%;
    margin-top: 20px;

    @include mediaToUp($lg) {
      margin-top: 40px;
    }
    &__title {
      margin-bottom: 8px;
    }
    &__table {
      width: 100%;

      &-header {
        padding: 10px 20px 10px 20px;
      }
      &-body {
      }
      &-row {
        position: relative;
        width: 100%;
        padding: 15px 20px;
        border-radius: 6px;
        background-color: #fff;
        border: 2px solid #4B5DFF;
      }
      &-cell {
        display: flex;
        align-items: center;
      }
      &-title {
        color: rgba(get($colors, black), 0.5);
        @include setFontSize(get($font-sizes, small));
      }
      &-text {
        @include setFontSize(get($font-sizes, small));

        @include mediaToUp($vl) {
          @include setFontSize(get($font-sizes, default));
        }
      }
      &-caption {
        color: rgba(get($colors, black), 0.5);
        @include setFontSize(get($font-sizes, small));
        margin-bottom: 10px;
      }
      &-name {
        display: flex;

        .icon {
          flex-shrink: 0;
          @include flexCenter();
          margin-right: 10px;
          margin-top: 2px;

          svg {
            width: 18px;
            height: 18px;
          }
        }
        .text {
          @include setFontSize(get($font-sizes, small));

          @include mediaToUp($vl) {
            @include setFontSize(get($font-sizes, default));
          }
        }
      }
      &-comment {
        flex-grow: 1;
        height: 58px;
        padding: 10px 20px;
        background: #F9F9F9;
        border-radius: 6px;
        @include setFontSize(get($font-sizes, small));

        &-inner {
          width: 100%;
          height: 100%;
          padding-right: 15px;
        }
        .simplebar-track.simplebar-vertical {
          width: 2px;
          background-color: #ececec;
          border-radius: 2px;
        }
        .simplebar-scrollbar {
          &:before {
            top: 0;
            left: 0;
            width: 100%;
            right: auto;
            background-color: rgba(get($colors, purple), 1);
            opacity: 1;
            border-radius: 2px;
          }
        }
      }
      &-btn-wr {
        margin-left: 20px;
        display: flex;
        align-items: center;

        @include mediaToDown($lg) {
          position: absolute;
          top: 15px;
          right: 20px;
          margin: 0;
        }
      }
      &-btn {
        @include flexCenter();
        margin-right: 20px;

        &:last-child {
          margin-right: 0;
        }
        svg {
          @include transition();
          fill: rgba(#5A5A5A, 0.5);
        }
        &--remove {
          @media (hover: hover) {
            cursor: pointer;

            &:hover {
              svg {
                fill: get($colors, orange);
              }
            }
          }
          svg {
            width: 12px;
            height: 12px;
          }
        }
        &--edit {
          @media (hover: hover) {
            cursor: pointer;

            &:hover {
              svg {
                fill: get($colors, btn-gray);
              }
            }
          }
          svg {
            width: 14px;
            height: 14px;
            fill: get($colors, purple);
          }
        }
        @include mediaToDown($lg) {
          margin-right: 15px;
        }
      }
    }
    &__main {
      width: 100%;
    }
    &__sub {
      width: 100%;
      margin-top: 20px;

      @include mediaToUp($lg) {
        margin-top: 40px;
      }
      &-inner {
        @include mediaToUp($lg) {
          padding: 5px 20px 20px 20px;
          background-color: #fff;
          border-radius: 6px;
        }
      }
      .profile-company {
        &__table {
          &-row {
            background-color: #fff;
            border: none;

            @include mediaToUp($lg) {
              background-color: rgba(#F3F3F9, 0.5);
            }
          }
          &-comment {
            @include mediaToUp($lg) {
              background-color: #fff;
            }
          }
        }
      }
    }
    &__add-button {
      display: block;
      margin-top: 20px;

      .add-button {
        padding-left: 0;
        padding-right: 0;
      }
    }
    &__form {
    }
  }
  /* autopark page */
  &-autopark {
    width: 100%;
    margin-top: 20px;

    @include mediaToUp($lg) {
      margin-top: 30px;
    }
    &__add-button {
      display: block;
      width: 100%;

      .add-button {
        .text {
          text-decoration: underline;
        }
      }
    }
    &__tabs {
      width: 100%;
    }
  }
  /* autopark create page */
  &-autopark-create {
    width: 100%;
    margin-top: 30px;

    .add-button {
      justify-content: flex-start;
      border: none;

      .icon {
        display: flex;
      }
    }
  }
  /* drivers page */
  &-drivers {
    width: 100%;
    margin-top: 20px;

    @include mediaToUp($lg) {
      margin-top: 30px;
    }
    &__add-button {
      width: 100%;
      display: block;

      .add-button {
        .text {
          text-decoration: underline;
        }
      }
    }
    &__tabs {
      width: 100%;
    }
  }
  /* managers page */
  &-managers {
    width: 100%;
    margin-top: 20px;

    @include mediaToUp($lg) {
      margin-top: 30px;
    }
    &__add-button {
      display: block;
      width: 100%;

      .add-button {
        .text {
          text-decoration: underline;
        }
      }
    }
    &__tabs {
      width: 100%;
    }
  }
  /* payment page */
  &-payment {
    width: 100%;
    margin-top: 30px;

    &__tabs {
      width: 100%;

      .profile {
        &__tabs {
          &-nav-item {
            @include mediaToDown($lg) {
              border-radius: 6px 6px 0 0;
            }
          }
          &-content {
            @include mediaToDown($lg) {
              margin-top: 0;
              padding: 20px;
              background: #F3F3F9;
              box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
              border-radius: 0 0 6px 6px;
            }
          }
        }
        &__table {
          &-header {
            padding-left: 20px;
          }
          &-row {
            padding-left: 20px;
          }
        }
      }
    }
    &__form {
      .form {
        &__item {
          margin-bottom: 40px;
        }
        &__label {
          background: none;
          height: auto;
          border-radius: 0;

          &--checkbox {
            margin-bottom: 12px;
          }
          &--info {
            padding-left: 35px;
          }
        }
        &__info {
          color: rgba(get($colors, black), 0.5);
          @include setFontSize(get($font-sizes, small));
          margin: 0;
        }
        &__checkbox {
          &:checked + .form__checkbox-icon {
            background-color: get($colors, purple);
          }
          &-icon {
            @include transition();
            background-color: #fff;

            svg {
              width: 12px;
              height: 12px;
              fill: #fff
            }
          }
          &-caption {
            margin-left: 15px;
          }
        }
      }
    }
  }
  /* reviews page */
  &-reviews {
    width: 100%;
    margin-top: 30px;
  }
  /* edit page */
  &-edit {
    width: 100%;
    margin-top: 30px;

    &__photo {
      display: flex;
      justify-content: flex-start;
    }
    &__form {
      margin-top: 20px;

      @include mediaToUp($lg) {
        margin-top: 40px;
      }
      .form {
        &__field {
          @include mediaToDown($md) {
            flex-direction: column;
          }
        }
      }
    }
  }
}
/* auth */
.auth {
  width: 100%;
  margin: 0 auto;

  &__title {
    @extend .page__title;
    margin-bottom: 20px;
  }
  &__form {
    width: 100%;
    background-color: #fff;
    box-shadow: 0 50px 100px rgba(0, 0, 0, 0.03);
    border-radius: 6px;
    padding: 50px 40px;

    @include mediaToDown($md) {
      padding: 20px;

      .form {
        &__link {
          width: 100%;
          @include setFontSize(get($font-sizes, small));
          text-align: center;
        }
      }
    }
  }
}
/* review */
.review-card {
  width: 100%;
  background-color: #fff;
  border-radius: 6px;
  margin-bottom: 10px;
  padding: 20px 30px 30px 30px;

  &:last-child {
    margin-bottom: 0;
  }
  &--view {
    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.19);
  }
  &__title, &__date {
    @include setFontSize(get($font-sizes, small));
    color: rgba(get($colors, black), 0.4);
    line-height: 1;
  }
  &__rating {
    .rating {
      &__star {
        padding-right: 5px;
      }
    }
  }
  &__btn {
    &:nth-child(odd) {
      @extend %button--border;
      color: get($colors, btn-gray);
      @include setFontSize(get($font-sizes, small));
      padding: 9px 22px;
      border-color: get($colors, btn-gray);
    }
    &:nth-child(even) {
      @extend %button--gray;
      @extend %button--form;
      @include setFontSize(get($font-sizes, small));
      padding: 10px 22px;
    }
  }
  &__content {
    &.open {
      .review-card__content-btn .icon {
        transform: rotate(180deg);
      }
    }
    &-preview {
      line-height: 1.5;
    }
    &-full {
      height: 0;
      overflow: hidden;
      line-height: 1.5;
    }
    &-btn {
      display: flex;
      align-items: center;
      margin-top: 1.5em;
      @include setFontSize(get($font-sizes, small));
      color: get($colors, purple);
      line-height: 1;

      .icon {
        @include transition;
        @include flexCenter();
        margin-left: 10px;

        svg {
          width: 10px;
          height: 10px;
          fill: get($colors, purple);
        }
      }
      @media (hover: hover) {
        &:hover {
          cursor: pointer;
        }
      }
    }
    .rating-detail {
      margin-top: 0.8em;
      margin-bottom: 1em;

      &__list {
        &-item {
          &-caption {
            color: #AEAEAE;
          }
        }
      }
    }
  }
  &__comment {
    &.open {
      .review-card__comment-btn {
        color: get($colors, black);
      }
    }
    &-btn {
      display: flex;
      align-items: center;
      margin-top: 1em;
      @include setFontSize(get($font-sizes, small));
      color: #7F7F7F;
      line-height: 1;
      text-decoration: underline;
      @include transition;

      @media (hover: hover) {
        &:hover {
          cursor: pointer;
          text-decoration-color: transparent;
        }
      }
    }
    &-name {
      @include setFontSize(get($font-sizes, small));
      color: rgba(get($colors, black), 0.3);
      margin-bottom: 0.5em;
    }
    &-list {
      height: 0;
      overflow: hidden;

      &-item {
        padding: 1em;
        background-color: #FAFAFA;
        border-bottom: 1px solid #DFDFDF;
        @include setFontSize(get($font-sizes, small));

        &:first-child {
          margin-top: 20px;
          border-radius: 6px 6px 0 0;
        }
        &:last-child {
          border-bottom: none;
          border-radius: 0 0 6px 6px;
        }
      }
    }
  }
  &__recommendation {
    display: flex;
    align-items: center;
    @include setFontSize(get($font-sizes, small));

    .icon {
      flex-shrink: 0;
      @include flexCenter();
      margin-right: 10px;

      svg {
        fill: get($colors, purple);
        width: 20px;
        height: 20px;
      }
    }
  }
  .rating-company {
    &__name, &__info {
      display: inline-flex;
    }
    &__info {
      margin-left: 4px;
    }
  }
}
/* reviews */
.reviews {
  margin-top: 20px;
  width: 100%;

  /* create review page */
  &-create {
    width: 100%;
    background-color: #FFFFFF;
    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.19);
    border-radius: 6px;
    padding: 30px 30px 40px 30px;

    &__header {
      width: 100%;

      .rating-company {
        padding-bottom: 20px;
        margin-bottom: 20px;
      }
    }
    &__title {
      @include setFontSize(get($font-sizes, middle));
      margin-bottom: 20px;
    }
    &__subtitle {
      @include setFontSize(get($font-sizes, default));
      font-family: $sans-semibold;
      margin-bottom: 20px;
    }
    &__form {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      &-left, &-right {
        width: 47%;
        display: flex;
        flex-direction: column;

        @include mediaToDown($lg) {
          width: 100%;
        }
      }
      &-footer {
        margin-top: 30px;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
      }
      &-title {
        @include setFontSize(get($font-sizes, default));
        margin-bottom: 20px;
        font-family: $sans-semibold;
      }
      &-label {
        margin-bottom: 15px;

        .form {
          &__caption {
            @include setFontSize(get($font-sizes, small));
            margin-bottom: 5px;
          }
        }
        &--text {
          .form {
            &__label {
              border: none;
              background-color: rgba(#eee, 0.7);
            }
            &__select {
              .ss-single-selected, .ss-multi-selected {
                background-color: transparent;
                border: none;
              }
            }
          }
        }
        &--textarea {
          .form {
            &__textarea {
              border: none;
              background-color: rgba(#eee, 0.7);
            }
          }
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
      &-button {
        margin-right: 10px;

        &:last-child {
          margin-right: 0;
        }
        &:not([type="submit"]) {
          @extend %button--border;
          padding: 15px 35px;
        }
        &[type="submit"] {
          @extend %button--gray;
          padding: 15px 35px;
        }
      }
      &-button-group {
        width: 47%;

        @include mediaToDown($lg) {
          width: 100%;
        }
      }
      &-link {
        color: rgba(get($colors, black), 0.4);
        text-decoration: underline;
        @include transition();
        margin-right: 40px;

        &:last-child {
          margin-right: 0;
        }
        @media (hover: hover) {
          &:hover {
            color: get($colors, purple);
            text-decoration-color: transparent;
          }
        }
        &:active {
          color: get($colors, purple);
          text-decoration-color: transparent;
        }
      }
      &-link-group {
        width: 47%;

        @include mediaToDown($lg) {
          width: 100%;
        }
      }
    }
  }
  &-sort {
    &__caption {
      color: rgba(get($colors, black), 0.4);
      @include setFontSize(get($font-sizes, small));
      margin-right: 1em;
    }
    &__select {
      min-width: 1px;

      &-label {
        margin-bottom: 0;
      }
      .ss-single-selected, .ss-multi-selected {
        background-color: transparent;
        border-color: transparent;
        color: rgba(get($colors, black), 0.4);
        @include setFontSize(get($font-sizes, small));

        .ss-arrow {
          margin-left: 0.6em;

          span {
            border-color: #878787;
          }
        }
      }
    }
  }
  &-info {
    width: 100%;

    &.fixed {
      position: fixed;
      z-index: 100;
      top: 0;
      left: 0;
      background-color: #fff;
      padding: 2em 2em 1em 2em;
      box-shadow: 0 10px 15px rgba(0,0,0,0.1);
    }
    &__header {
      border-bottom: 1px solid #DCDCDC;
      padding-bottom: 2em;
      margin-bottom: 1em;

      .rating-detail {
        &__list {
          &-item {
            margin-bottom: 0.2em;

            & > .row {
              flex-direction: row-reverse;
              justify-content: center;
            }
          }
        }
      }
      .rating-company {
        border: none;
        margin: 0;
        padding: 0;

        &__name {
          @include setFontSize(get($font-sizes, middle));
        }

        &__city, &__id {
          @include setFontSize(get($font-sizes, small));
        }
      }
    }
  }
}
/* 404 */
.error-box {
  @include flexCenterCol();

  &__title {
    text-align: center;
    color: get($colors, purple);
    @include setFontSize(120);
    line-height: 1;
    font-family: $rubik-medium;
    margin-bottom: 20px;

    @include mediaToDown($md) {
      @include setFontSize(50);
    }
  }
  &__text {
    color: get($colors, purple);
    text-align: center;
    @include setFontSize(get($font-sizes, large));
    line-height: 1;
    font-family: $rubik-light;

    @include mediaToDown($md) {
      @include setFontSize(get($font-sizes, default));
    }
  }
}