@import 'mixins';
@import 'rem';

/* Colors variables start */
$colors: (
  purple:   #4B5DFF,
  gray:     #eeeeee,
  black:    #101010,
  lilac:    #E7EAF0,
  orange:   #FF8710,
  btn-gray: #575757,
  green:    #35c402,
  btn-gray-hover: #444444
);
/* Colors variables end */

/* Fonts variables start */
$sans-reg: "OpenSansRegular", sans-serif;
$sans-semibold: "OpenSansSemiBold", sans-serif;
$sans-bold: "OpenSansBold", sans-serif;
$sans-light: "OpenSansLight", sans-serif;
$sans-italic: "OpenSansItalic", sans-serif;

$rubik-reg: "RubikRegular", sans-serif;
$rubik-medium: "RubikMedium", sans-serif;
$rubik-light: "RubikLight", sans-serif;


$font-sizes: (
  small: 14,
  default: 16,
  middle: 18,
  middle-title: 20,
  middle-up-title: 22,
  middle-up: 24,
  large: 28
);
/* Fonts variables end */

/* Reboot variables start */
$font-family-base: 														-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Helvetica Neue", Arial, sans-serif;
$font-size-base: 															1rem;
$font-weight-base: 														normal;
$line-height-base: 														1.35;

$body-color: 																	get($colors, black);
$body-bg: 																		get($colors, gray);

$link-hover-decoration:                       none;


  /* Grid variables start */
$grid-breakpoints: (
  xs: 																			0,
  sm: 																			576px,
  md: 																			768px,
  lg: 																			992px,
  xl: 																			1200px,
  vl:                                       1400px,
  sl:                                       1600px
);

//media queries
//$m:  375px;
//$sm: 576px;
//$md: 768px;
//$lg: 992px;
//$xl: 1200px;
//$vl: 1400px;
//$sl: 1600px;
//$hd: 1900px

$container-max-widths: (
  sm: 																			540px,
  md: 																			720px,
  lg: 																			960px,
  xl: 																			1200px,
  vl:                                       1340px,
  sl:                                       1440px
);

$grid-columns:                								12;
$grid-gutter-width:           								16px;
/* Grid variables end */

/* Responsive font-size start */
$rfs-minimum-font-size: 											16px;
$rfs-font-size-unit: 													rem;
$rfs-breakpoint: 															get($grid-breakpoints, xl);
$rfs-breakpoint-unit: 												px;
$rfs-two-dimensional: 												false;
$rfs-factor: 																	10;
$rfs-class: 																	false;
$rfs-rem-value: 															16;
$rfs-safari-iframe-resize-bug-fix: 						false;
$enable-responsive-font-sizes: 								true;
/* Responsive font-size end */

/*Common variables start */

$spacer:																			rem(10px);

/*Common variables end */

