$m:  375px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$vl: 1400px;
$sl: 1600px;
$hd: 1900px;

@mixin mediaOnly($widthFrom, $widthTo, $device: 'all') {
 @media #{$device} and (min-width: #{$widthFrom}) and (max-width: #{$widthTo - 1px}) {
    @content;
 }
}

@mixin mediaToUp($width, $device: 'all') {
 @media #{$device} and (min-width: #{$width}) {
   @content;
 }
}

@mixin mediaToDown($width, $device: 'all') {
 @media #{$device} and (max-width: #{$width - 1px}) {
   @content;
 }
}